import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Steps, Button, Form, Card } from 'antd';
import CustomPage from '@/components/CustomPage';
import CustomForm from '@/components/CustomForm';
import Message from '@/components/Message';
import DndFile from './DndFile';
import DataMapping from './DataMapping';
import TableImport from './TableImport';
import { useData } from '@/context';
import { convertDateQr } from '@/utils/date';

interface PanelQrImportInterface {
  title: string;
  data: Record<string, any>[];
  selectedColumns: string[];
}

const PanelQrImport = ({
  title,
  data,
  selectedColumns,
}: PanelQrImportInterface) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [current, setCurrent] = useState(0);
  const [csv, setCSV] = useState<any>(null);
  const [defaultFileList, setDefaultFileList] = useState<any>([]);
  const [listSiteCode, setListSiteCode] = useState<any>([]);
  const { onPostData } = useData();
  const [mappingValue, setMappingValue] = useState<any>([]);

  const steps = [
    {
      title: t('select_file'),
      content: (
        <DndFile
          form={form}
          defaultFileList={defaultFileList}
          setDefaultFileList={setDefaultFileList}
          setCSV={setCSV}
        />
      ),
    },
    {
      title: t('mapping_field'),
      content: <DataMapping csv={csv} />,
    },
    {
      title: t('Import'),
      content: (
        <TableImport
          mappingValue={mappingValue}
          listSiteCode={listSiteCode}
          setListSiteCode={setListSiteCode}
        />
      ),
    },
  ];

  const prev =
    current === 1 || current === 2 ? (
      <Button
        key="prev"
        size="large"
        type="default"
        style={{ width: '250px' }}
        loading={loading}
        onClick={() => setCurrent(current - 1)}
      >
        {t('previous')}
      </Button>
    ) : null;

  const next =
    current >= 0 && current < 2 ? (
      <Button
        key="submit"
        size="large"
        type="primary"
        htmlType="submit"
        loading={loading}
        style={{ width: '250px' }}
      >
        {t('next')}
      </Button>
    ) : null;

  const submit =
    current === 2 ? (
      <Button
        key="submit"
        size="large"
        type="primary"
        htmlType="submit"
        loading={loading}
        style={{ width: '250px' }}
      >
        {t('submit')}
      </Button>
    ) : null;

  const buttons = [prev, next, submit].filter((f) => f);

  const handleMappingValue = (mock: any) => {
    const mapping = (csv.data || []).map((record: any) => {
      return Object.keys(mock).reduce((result: any, schemaKey) => {
        const schemaValue = (mock?.[schemaKey] || [])
          .map((schemaValueKey: string) => {
            const value = record?.[schemaValueKey] || '';

            return value;
          })
          .join(' ');

        result[schemaKey] = schemaValue || '';

        return result;
      }, {});
    });

    const mappedData = mapping.map((item: any, index: number) => ({
      index,
      ...item,
    }));

    setMappingValue(mappedData);
  };

  const handleSubmit = async (value: any) => {
    setLoading(true);

    const payload = mappingValue.map((item: any, index: number) => {
      const startDate = convertDateQr(
        new Date(value[`qrcodeStartDate_${index}`])
      );

      return {
        ...item,
        receiver: [item.receiver],
        numUse: value[`qrcodeNumUse_${index}`],
        startDate: startDate,
        deliveryMethod: 'email',
        siteCodes: listSiteCode[index],
      };
    });

    const result = await onPostData('qr-code/bulk', payload);

    if (result.error) {
      Message.warning(result.message || t('qrcode_input_configuration_failed'));
      setLoading(false);
      return;
    }

    const success =
      result.success.count &&
      `${t('success')} ${t('send')} ${result.success.count} ${t('email')}`;
    const failed =
      result.failed.count &&
      `${t('failure')} ${t('send')} ${result.failed.count} ${t('email')}`;
    const messages = [failed, success].filter((message) => message).join(', ');

    form.resetFields();

    if (result.failed.count) {
      await Message.warning(messages);
    } else {
      await Message.success(messages);
    }

    setLoading(false);
    setCurrent(0);
    setCSV(null);
    setDefaultFileList([]);
    setListSiteCode([]);
    form.resetFields();
  };

  const onFinish = async (value: any) => {
    const currentPosition = current + 1;
    const submit = currentPosition === 3;
    const mapping = currentPosition === 2;

    if (mapping) {
      handleMappingValue(value);
    }

    if (submit) {
      await handleSubmit(value);
    } else {
      setCurrent(currentPosition);
    }
  };

  return (
    <CustomPage title={t('qrcode_import')} style={{ maxHeight: 860 }}>
      <Steps current={current} style={{ marginBottom: '30px' }}>
        {steps.map((item) => (
          <Steps.Step key={item.title} title={item.title} />
        ))}
      </Steps>

      <CustomForm
        form={form}
        layout="vertical"
        actions={buttons}
        onFinish={onFinish}
        style={{ width: 1000 }}
      >
        <Card>{steps[current].content}</Card>
      </CustomForm>
    </CustomPage>
  );
};

export default PanelQrImport;
