import {
  QrcodeOutlined,
  UnorderedListOutlined,
  UserOutlined,
  SettingOutlined,
  GlobalOutlined,
  FileExcelOutlined,
  BookOutlined,
} from '@ant-design/icons';

import { RoutesInterface } from '@/interfaces/routes';
import {
  PanelLicense,
  PanelLogin,
  PanelDashboard,
  PanelQr,
  PanelQrImport,
  PanelQrList,
  PanelUser,
  PanelUserDetail,
  PanelSiteCode,
  PanelSiteCodeDetail,
  PanelSettings,
  PanelQrSearch,
  PanelRequestedQr,
} from '@/pages';

import { HasLicenseGuard } from './guards/has-license.guard';
import { HasLoginGuard } from './guards/has-login.guard';
import { ShouldLoginGuard } from './guards/should-login.guard';
import { ShouldLicensedGuard } from './guards/should-licensed.guard';
import { RoleAdminGuard } from './guards/role-admin.guard';
import { HasLineFeatureGuard } from './guards/has-line-feature.guard';

const routes: RoutesInterface[] = [
  {
    path: '/init-license',
    exact: true,
    component: PanelLicense,
    guards: [HasLicenseGuard],
  },
  {
    path: '/',
    exact: true,
    component: PanelLogin,
    guards: [ShouldLicensedGuard, HasLoginGuard],
  },
  {
    path: '/panel',
    exact: true,
    component: PanelDashboard,
    guards: [ShouldLicensedGuard, ShouldLoginGuard],
  },
  {
    path: '/panel/qr',
    exact: true,
    component: PanelQr,
    guards: [ShouldLicensedGuard, ShouldLoginGuard],
    icon: <QrcodeOutlined className="icon" />,
    title: 'qrcode',
    isUser: true,
    isAdmin: true,
    isSub: false,
  },
  {
    path: '/panel/requested-qr',
    exact: true,
    component: PanelRequestedQr,
    guards: [
      ShouldLicensedGuard,
      ShouldLoginGuard,
      RoleAdminGuard,
      HasLineFeatureGuard,
    ],
    icon: <BookOutlined className="icon" />,
    title: 'requested_qr',
    isUser: false,
    isAdmin: true,
    isSub: true,
    isLine: true,
  },
  {
    path: '/panel/qr/list',
    exact: true,
    component: PanelQrList,
    guards: [ShouldLicensedGuard, ShouldLoginGuard],
    icon: <UnorderedListOutlined className="icon" />,
    title: 'qrcode_list',
    isUser: true,
    isAdmin: true,
    isSub: false,
  },
  {
    path: '/panel/qr/import',
    exact: true,
    component: PanelQrImport,
    guards: [ShouldLicensedGuard, ShouldLoginGuard],
    icon: <FileExcelOutlined className="icon" />,
    title: 'qrcode_import',
    isUser: true,
    isAdmin: true,
    isSub: false,
  },
  {
    path: '/panel/user',
    exact: true,
    component: PanelUser,
    guards: [ShouldLicensedGuard, ShouldLoginGuard, RoleAdminGuard],
    icon: <UserOutlined className="icon" />,
    title: 'users',
    isUser: false,
    isAdmin: true,
    isSub: false,
  },
  {
    path: '/panel/user/:id',
    exact: true,
    component: PanelUserDetail,
    guards: [ShouldLicensedGuard, ShouldLoginGuard, RoleAdminGuard],
  },
  {
    path: '/panel/sitecode',
    exact: true,
    component: PanelSiteCode,
    guards: [ShouldLicensedGuard, ShouldLoginGuard, RoleAdminGuard],
    icon: <GlobalOutlined className="icon" />,
    title: 'site_code',
    isUser: false,
    isAdmin: true,
    isSub: true,
  },
  {
    path: '/panel/sitecode/:id',
    exact: true,
    component: PanelSiteCodeDetail,
    guards: [ShouldLicensedGuard, ShouldLoginGuard, RoleAdminGuard],
  },
  {
    path: '/panel/settings',
    exact: true,
    component: PanelSettings,
    guards: [ShouldLicensedGuard, ShouldLoginGuard, RoleAdminGuard],
    icon: <SettingOutlined className="icon" />,
    title: 'settings',
    isUser: false,
    isAdmin: true,
    isSub: true,
  },
  {
    path: '/search',
    exact: true,
    component: PanelQrSearch,
    guards: [ShouldLicensedGuard],
  },
];

export default routes;
