import React, { useContext, useState } from 'react';
import { InputNumber } from 'antd';

import CustomFormItem from '@/components/CustomFormItem';
import { EditableContext } from './EditableRow';
import SelectSiteCode from '@/components/SelectSiteCode';
import { useTranslation } from 'react-i18next';

interface Item {
  key: string;
  name: string;
  age: string;
  address: string;
}

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (record: Item) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const { t } = useTranslation();
  const [editing, setEditing] = useState(false);
  const form = useContext(EditableContext)!;

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  const EditableComponent =
    (dataIndex as string) === 'siteCode' ? (
      <SelectSiteCode
        name={dataIndex}
        noLabel
        onChange={save}
        style={{ margin: '0px' }}
      />
    ) : (
      <CustomFormItem
        name={dataIndex}
        rules={[{ required: true, message: t('complete_this_field') }]}
        style={{ margin: '0px' }}
      >
        <InputNumber
          type="number"
          max={99}
          min={0}
          precision={0}
          onPressEnter={save}
          onBlur={save}
          placeholder={t('qrcode_num_use')}
        />
      </CustomFormItem>
    );

  if (editable) {
    childNode = editing ? (
      EditableComponent
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

export default EditableCell;
