enum Cookie {
  Host = 'bs-qrg-host',
  Session = 'bs-qrg-session',
  SessionName = 'bs-qrg-session-name',
  Token = 'bs-qrg-token',
  LogId = 'bs-qrg-id',
  Lang = 'bs-qrg-lang',
}

export default Cookie;
