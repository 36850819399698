import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Card,
  DatePicker,
  Form,
  Select,
  InputNumber,
  Input,
} from 'antd';

import CustomPage from '@/components/CustomPage';
import CustomForm from '@/components/CustomForm';
import CustomFormItem from '@/components/CustomFormItem';
import Message from '@/components/Message';

import { emailRegex } from '@/utils/regex';
import layout from '@/utils/layout';
import { useData, useGlobal } from '@/context';
import { RuleObject } from 'antd/lib/form';
import { StoreValue } from 'antd/lib/form/interface';
import { convertDateQr } from '@/utils/date';
import SelectMultipleSiteCode from '@/components/SelectMultipleSiteCode';
import SearchLineProfile from '@/components/SearchLineProfile';

const TODAY = Date.now();

const PanelQr = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { onPostData } = useData();
  const { hasLineFeature } = useGlobal();
  const [loading, setLoading] = useState<boolean>(false);
  const [skeleton] = useState<boolean>(false);
  const [listSiteCode, setListSiteCode] = useState<any>([]);
  const [startDate, setStartDate] = useState<any>('');
  const [delivery, setDelivery] = useState<any>('email');

  const datetimeFormat = 'YYYY-MM-DD';

  const emailValidator = (
    rule: RuleObject,
    values: StoreValue,
    callback: (error?: string) => void
  ) => {
    const invalidInputs = values.filter(
      (value: string) => !value.match(emailRegex)
    );
    if (invalidInputs.length === 0) {
      callback();
    } else if (invalidInputs.length === 1) {
      callback(`${invalidInputs.join('')} ${t('invalid_email')}`);
    } else {
      callback(
        `${invalidInputs.slice(0, -1).join(', ')} and ${invalidInputs.slice(
          -1
        )} ${t('invalid_email')}`
      );
    }
  };

  const onFinish = async (value: any) => {
    setLoading(true);

    const startDate = convertDateQr(new Date(value.qrcodeStartDate));

    const receiver =
      value.qrcodeDeliveryMethod === 'line'
        ? value.qrcodeReceiver.map((item: any) => item.value)
        : value.qrcodeReceiver;

    const payload = {
      companyName: value.companyName,
      userName: value.userName,
      numUse: Number(value.qrcodeNumUse),
      deliveryMethod: value.qrcodeDeliveryMethod,
      receiver: receiver,
      startDate: startDate,
      siteCodes: listSiteCode,
    };

    const result = await onPostData('qr-code', payload);

    if (result.error) {
      Message.warning(result.message || t('qrcode_input_configuration_failed'));
      setLoading(false);
      return;
    }

    const success =
      result.success.count &&
      `${t('success')} ${t('send')} ${result.success.count}`;
    const failed =
      result.failed.count &&
      `${t('failure')} ${t('send')} ${result.failed.count}`;
    const messages = [failed, success].filter((message) => message).join(', ');

    form.resetFields();

    if (result.failed.count) {
      await Message.warning(messages);
    } else {
      await Message.success(messages);
    }

    setLoading(false);
  };

  return (
    <CustomPage title={t('qrcode')}>
      <CustomForm
        form={form}
        skeleton={skeleton}
        onFinish={onFinish}
        col={layout.form}
        style={{ width: 550 }}
        initialValues={{ qrcodeDeliveryMethod: 'email' }}
        actions={[
          <Form.Item shouldUpdate style={{ marginRight: 24, marginLeft: 24 }}>
            {() => (
              <Button
                key="submit"
                size="large"
                type="primary"
                htmlType="submit"
                loading={loading}
                style={{ width: 550 }}
                disabled={
                  form.getFieldsError().filter(({ errors }) => errors.length)
                    .length > 0
                }
              >
                {t('create')}
              </Button>
            )}
          </Form.Item>,
        ]}
      >
        <Card>
          <CustomFormItem
            name="companyName"
            skeleton={skeleton}
            label={t('company_name')}
            rules={[{ required: true, message: t('complete_this_field') }]}
            labelCol={{ lg: { span: 8 } }}
          >
            <Input placeholder={t('company_name')} maxLength={150} />
          </CustomFormItem>

          <CustomFormItem
            name="userName"
            skeleton={skeleton}
            label={t('user_name')}
            rules={[{ required: true, message: t('complete_this_field') }]}
            labelCol={{ lg: { span: 8 } }}
          >
            <Input placeholder={t('user_name')} maxLength={150} />
          </CustomFormItem>

          <CustomFormItem
            name="qrcodeNumUse"
            skeleton={skeleton}
            label={t('qrcode_num_use')}
            rules={[{ required: true, message: t('complete_this_field') }]}
            labelCol={{ lg: { span: 8 } }}
          >
            <InputNumber
              type="number"
              max={99}
              min={0}
              precision={0}
              placeholder={t('qrcode_num_use')}
            />
          </CustomFormItem>

          <CustomFormItem
            name="qrcodeDeliveryMethod"
            skeleton={skeleton}
            label={t('qrcode_delivery_method')}
            rules={[{ required: true, message: t('complete_this_field') }]}
            labelCol={{ lg: { span: 8 } }}
          >
            <Select
              style={{ width: '100%' }}
              onChange={(value) => setDelivery(value)}
            >
              <Select.Option value="email">{t('email')}</Select.Option>
              {hasLineFeature && (
                <Select.Option value="line">{t('line')}</Select.Option>
              )}
            </Select>
          </CustomFormItem>

          {delivery === 'email' ? (
            <CustomFormItem
              name="qrcodeReceiver"
              skeleton={skeleton}
              label={t('qrcode_receiver')}
              rules={[
                { required: true, message: t('complete_this_field') },
                {
                  validator: emailValidator,
                },
              ]}
              labelCol={{ lg: { span: 8 } }}
            >
              <Select mode="tags" placeholder={t('qrcode_receiver')} />
            </CustomFormItem>
          ) : (
            <SearchLineProfile />
          )}

          <CustomFormItem
            name="qrcodeStartDate"
            skeleton={skeleton}
            label={t('qrcode_start_date')}
            rules={[{ required: true, message: t('complete_this_field') }]}
            labelCol={{ lg: { span: 8 } }}
          >
            <DatePicker
              style={{ width: '100%' }}
              format={datetimeFormat}
              onChange={(value) => setStartDate(value)}
              disabledDate={(current) => {
                return current && current.valueOf() < TODAY;
              }}
              disabledTime={(current: any) => {
                return current && current.valueOf() < TODAY;
              }}
            />
          </CustomFormItem>

          <SelectMultipleSiteCode
            form={form}
            startDate={startDate}
            listSiteCode={listSiteCode}
            setListSiteCode={setListSiteCode}
          />
        </Card>
      </CustomForm>
    </CustomPage>
  );
};

export default PanelQr;
