import { Redirect } from 'react-router';
import { GuardInterface } from '@/interfaces/guard';
import { useGlobal } from '@/context';

export const HasLineFeatureGuard = ({ children }: GuardInterface) => {
  const { hasLineFeature } = useGlobal();

  if (!hasLineFeature) return <Redirect to={{ pathname: '/' }}></Redirect>;

  return <>{children}</>;
};
