import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import CustomPage from '@/components/CustomPage';

import List from './List';
import CreateUpdate from './CreateUpdate';
import useGetUsers from '@/hooks/useGetUsers';

const PanelUserList = () => {
  const { t } = useTranslation();
  const { data, refetch } = useGetUsers();

  return (
    <CustomPage title={t('user')}>
      <Tabs type="card" tabPosition="left">
        <Tabs.TabPane tab={t('list_user')} key="1">
          <List data={data} refetch={refetch} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('create_user')} key="2">
          <CreateUpdate refetch={refetch} />
        </Tabs.TabPane>
      </Tabs>
    </CustomPage>
  );
};

export default PanelUserList;
