import { FC, useState, useContext, useCallback, createContext } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import Cookie from '@/interfaces/cookie';
import Role from '@/interfaces/role';
import Message from '@/components/Message';

const useValue = () => {
  const { t } = useTranslation();
  const [cookies, setCookie, removeCookie] = useCookies([
    Cookie.Session,
    Cookie.SessionName,
    Cookie.Token,
    Cookie.LogId,
  ]);
  const [hasToken] = useState(cookies[Cookie.Token]);
  const [role] = useState(cookies[Cookie.Session]);
  const [logId] = useState(cookies[Cookie.LogId]);
  const [logName] = useState(cookies[Cookie.SessionName]);

  const onLogin = useCallback(
    async (payload: any) => {
      if (payload.meta.status !== 200) {
        return;
      }

      await Message.success(t('success'));
      setCookie(Cookie.Session, payload.role);
      setCookie(Cookie.Token, payload.token);
      setCookie(Cookie.LogId, payload.id);
      setCookie(Cookie.SessionName, payload.username);

      setTimeout(() => {
        window.location.reload();
      });
    },
    [setCookie, t]
  );

  const onLogout = useCallback(async () => {
    removeCookie(Cookie.Session);
    removeCookie(Cookie.Token);
    removeCookie(Cookie.LogId);
    removeCookie(Cookie.SessionName);

    window.location.href = '/';
  }, [removeCookie]);

  const isSuperAdmin = role === Role.Admin && logName === 'admin';
  const isAdmin = role === Role.Admin;
  const isSub = role === Role.Sub;
  const isUser = role === Role.User;

  return {
    logId,
    logName,
    isAdmin,
    isSub,
    isUser,
    isSuperAdmin,
    hasToken,
    onLogin,
    onLogout,
  };
};

const AuthContext = createContext({} as ReturnType<typeof useValue>);
const useAuth = () => useContext(AuthContext);
const AuthProvider: FC<any> = (props) => {
  return <AuthContext.Provider value={useValue()} {...props} />;
};

export { AuthProvider, useAuth };
