import i18n from 'i18next';
import config from '@/config/app';
import { initReactI18next } from 'react-i18next';

const en = require('@/locales/en');
const jp = require('@/locales/jp');
const defaultLang = config.app.defaultLang;

i18n.use(initReactI18next).init({
  lng: defaultLang,
  keySeparator: false,
  defaultNS: 'translation',
  fallbackLng: defaultLang,
  // wait: process && !process.release,
  react: { wait: true, useSuspense: false },
  debug: process.env.NODE_ENV === 'development',
  interpolation: { escapeValue: false, formatSeparator: ',' },
  resources: { en: { translation: en }, jp: { translation: jp } },
});

export default i18n;
