import { useRef, useState, useEffect } from 'react';

export default function useDebounce(params: any, delay = 500, cb?: () => void) {
  const [value, setValue] = useState(params);
  const previousValue = useRef(params);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (previousValue.current !== params) {
        setValue(params);
        previousValue.current = params;

        if (cb) {
          cb();
        }
      }
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [cb, params, delay, value]);

  return value;
}
