import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, Input, Form, Typography, Steps, Modal } from 'antd';

import CustomPage from '@/components/CustomPage';
import CustomForm from '@/components/CustomForm';
import CustomFormItem from '@/components/CustomFormItem';

import { emailRegex } from '@/utils/regex';
import { useData } from '@/context';
import { RuleObject } from 'antd/lib/form';
import { StoreValue } from 'antd/lib/form/interface';
import { PanelQrList } from '..';

const PanelSearch = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { onFetchData } = useData();
  const [loading, setLoading] = useState<boolean>(false);
  const [current, setCurrent] = useState(0);
  const [bookingId, setBookingId] = useState('');
  const [data, setData] = useState([]);

  const emailValidator = (
    _: RuleObject,
    value: StoreValue,
    callback: (error?: string) => void
  ) => {
    const validInput = Boolean(value.match(emailRegex));

    if (validInput) {
      callback();
    } else {
      callback(t('invalid_email'));
    }
  };

  const fetchQRCodeList = useCallback(
    async (payload) => {
      try {
        const res = await onFetchData('qr-codes', payload);

        if (res.error) {
          Modal.error({
            title: t('error_title'),
            content: t('error_content'),
          });
          setData([]);
        } else {
          if (!res.data.length) {
            Modal.error({
              title: t('error_title'),
              content: t('error_content'),
            });
          } else {
            setData(res.data);
          }
        }
      } catch (error) {
        Modal.error({
          title: t('error_title'),
          content: t('error_content'),
        });
        setData([]);
      } finally {
        setLoading(false);
      }
    },
    [onFetchData, t]
  );

  const steps = [
    {
      title: t('language'),
      content: (
        <>
          <Typography.Title
            level={5}
            style={{ margin: '0px 130px 20px', textAlign: 'center' }}
          >
            Please input your booking ID And press next button
          </Typography.Title>
          <CustomFormItem
            name="booking_id"
            rules={[{ required: true, message: t('complete_this_field') }]}
          >
            <Input.Password placeholder={t('booking_id')} />
          </CustomFormItem>
        </>
      ),
    },
    {
      title: t('language'),
      content: (
        <>
          <Typography.Title
            level={5}
            style={{ margin: '0px 120px 20px', textAlign: 'center' }}
          >
            Please input your Email address And press next button
          </Typography.Title>
          <CustomFormItem
            name="email"
            rules={[
              { required: true, message: t('email') },
              {
                validator: emailValidator,
              },
            ]}
            labelCol={{ lg: { span: 12 } }}
          >
            <Input.Password placeholder={t('email')} />
          </CustomFormItem>
        </>
      ),
    },
  ];

  const prev = (
    <Button
      key="prev"
      size="large"
      type="default"
      style={{ width: '250px' }}
      loading={loading}
      onClick={() => setCurrent(current - 1)}
    >
      {t('previous')}
    </Button>
  );

  const next = (
    <Button
      key="submit"
      size="large"
      type="primary"
      htmlType="submit"
      loading={loading}
      style={{ width: '250px' }}
    >
      {t('next')}
    </Button>
  );

  const buttons = [current > 0 ? prev : null, next].filter((f) => f);

  const onFinish = async (value: any) => {
    const currentPosition = current + 1;
    const submit = currentPosition === 2;

    if (submit) {
      setLoading(true);
      fetchQRCodeList({ bookingId, email: value.email });
      setLoading(false);
    } else {
      setBookingId(value.booking_id);
      setCurrent(currentPosition);
    }
  };

  const handleFinish = () => {
    form.resetFields();
    setCurrent(0);
    setBookingId('');
    setData([]);
  };

  return (
    <CustomPage backButton={[<></>]}>
      {data.length ? (
        <div>
          <PanelQrList initialData={data} callBack={handleFinish} />
          <Button
            key="prev"
            size="large"
            type="default"
            style={{ width: '250px', margin: '10px auto', display: 'block' }}
            loading={loading}
            onClick={handleFinish}
          >
            {t('cancel')}
          </Button>
        </div>
      ) : (
        <div>
          <Steps current={current} style={{ display: 'none' }}>
            {steps.map((item) => (
              <Steps.Step key={item.title} title={item.title} />
            ))}
          </Steps>

          <CustomForm
            form={form}
            layout="vertical"
            actions={buttons}
            onFinish={onFinish}
            style={{ width: 550 }}
          >
            <Card>{steps[current].content}</Card>
          </CustomForm>
        </div>
      )}
    </CustomPage>
  );
};

export default PanelSearch;
