import moment from 'moment';
import { useAuth } from '@/context';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState, useCallback } from 'react';
import { Table, Modal, Space, Row, Col, Input, Button } from 'antd';

import { useData } from '@/context';
import ExportCsv from '@/components/ExportCsv';
import PrintQRCode from '@/components/PrintQRCode';
import ResendQRCode from '@/components/ResendQRCode';
import DownloadQRCode from '@/components/DownloadQRCode';
import CustomPage from '@/components/CustomPage';
import CustomCard from '@/components/CustomCard';
import BackButton from '@/components/BackButton';
import useDebounce from '@/hooks/useDebounce';
import { safeJSONparse } from '@/utils/string';
import ModalSiteCodeDetail from '@/components/ModalSiteCodeDetail';

const formatDate = (date: string): string => {
  if (!date) return '';

  return moment(date).format('DD MMMM yyyy');
};

interface PanelQRListInterface {
  initialData?: Record<string, any>[];
  callBack?: () => void;
}

const PanelQRList = ({ initialData, callBack }: PanelQRListInterface) => {
  const { t } = useTranslation();
  const { onFetchData } = useData();
  const { isAdmin } = useAuth();
  const { Search } = Input;
  const [loading, setLoading] = useState<boolean>(false);
  const [, setSkeleton] = useState<boolean>(false);
  const [data, setData] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [page, setPage] = useState<number>(1);
  const date = moment(new Date()).format('YYYY-MM-DD-HH-mm-ss');
  const filename = `${date}-QR-List.csv`;
  const [showModalSiteCodeDetail, setShowModalSiteCodeDetail] = useState(false);
  const [selectedSiteCode, setSelectedSiteCode] = useState(null);

  const columns: any[] = [
    {
      title: t('booking_id'),
      dataIndex: 'bookingId',
      width: 90,
    },
    {
      title: t('qr_id'),
      dataIndex: 'id',
      width: 90,
    },
    {
      title: t('qrcode_start_date'),
      dataIndex: 'startDate',
      render: (date: string) => formatDate(date),
      width: 150,
    },
    {
      title: t('qrcode_delivery_method'),
      dataIndex: 'deliveryMethod',
      width: 100,
      render: (deliveryMethod: string) => {
        // TODO: Need add more delivery method options.
        return deliveryMethod === 'email' ? t('email') : t('others');
      },
    },
    {
      title: t('delivery_status'),
      dataIndex: 'deliveryStatus',
      width: 100,
      render: (status: string) => {
        return status === 'failure' ? t('failure') : t('success');
      },
    },
    {
      title: t('qrcode_num_use'),
      dataIndex: 'numUse',
      width: 100,
    },
    {
      title: t('access'),
      dataIndex: 'siteCodes',
      width: 100,
      render: (_: any, record: any) => {
        const list = safeJSONparse(record.siteCodes, []);

        return (
          <Button
            type="link"
            style={{ padding: 0 }}
            onClick={() => {
              setSelectedSiteCode(record.siteCodes);
              setShowModalSiteCodeDetail(true);
            }}
          >
            {list.length} ({t('access')})
          </Button>
        );
      },
    },
    {
      title: t('qrcode_receiver'),
      dataIndex: 'receiver',
      width: 200,
    },
    {
      title: t('action'),
      dataIndex: 'action',
      width: 100,
      render: (_: string, record: Record<string, string>) => {
        return (
          <Space>
            <DownloadQRCode ids={[record.id]} />
            <ResendQRCode ids={[record.id]} />
            <PrintQRCode ids={[record.id]} />
          </Space>
        );
      },
    },
  ];

  const backButton = useMemo(() => {
    const offset = (page - 1) * 10;
    const backButton = [<BackButton />];

    if (isAdmin) {
      backButton.unshift(<ExportCsv page={offset} fileName={filename} />);
    }

    return backButton;
  }, [page, isAdmin, filename]);

  const fetchQRCodeList = useCallback(
    async (search = '') => {
      setSkeleton(true);

      try {
        const res = await onFetchData('qr-codes', search ? { search } : {});

        if (res.error) {
          Modal.error({
            title: t('error_title'),
            content: t('error_content'),
          });
          setData([]);
        } else {
          setData(res.data);
        }
      } catch (error) {
        Modal.error({
          title: t('error_title'),
          content: t('error_content'),
        });
        setData([]);
      } finally {
        setSkeleton(false);
        setLoading(false);
      }
    },
    [onFetchData, t]
  );

  const search = () => {
    fetchQRCodeList(searchValue);
  };

  const rowSelection = {
    onChange: (_: any, selectedRows: any) => {
      setSelectedRow(selectedRows);
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.name === 'Disabled User',
      name: record.name,
    }),
  };

  const ids = useMemo(
    () => selectedRow.map((item: any) => item.id),
    [selectedRow]
  );

  useDebounce(searchValue, 500, search);

  useEffect(() => {
    if (initialData) {
      setData(initialData);
    } else {
      fetchQRCodeList();
    }
  }, [fetchQRCodeList, initialData]);

  const TableComponent = (
    <>
      <CustomCard style={{ maxWidth: 1000 }}>
        <Row style={{ marginBottom: 20 }}>
          <Col span={12}>
            {Boolean(!initialData) && (
              <Space align="start">
                <Search
                  onChange={(e) => setSearchValue(e.target.value)}
                  placeholder="Search SQpass"
                  onSearch={(value) => setSearchValue(value)}
                  style={{ width: 300 }}
                />
              </Space>
            )}
          </Col>
          <Col span={12}>
            {Boolean(selectedRow.length) && (
              <Space
                align="end"
                style={{ justifyContent: 'end', width: '100%' }}
              >
                <DownloadQRCode
                  ids={ids}
                  icon={null}
                  type="default"
                  title={t('download')}
                  callBack={callBack}
                />
                <ResendQRCode
                  ids={ids}
                  icon={null}
                  type="default"
                  title={t('resend_qr_code')}
                  callBack={callBack}
                />
                <PrintQRCode
                  ids={ids}
                  icon={null}
                  type="default"
                  title={t('print_qr_code')}
                  callBack={callBack}
                />
              </Space>
            )}
          </Col>
        </Row>
        <Table
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          key={'qr'}
          onChange={(value) => setPage(value.current || 0)}
          columns={columns}
          dataSource={data}
          loading={loading}
          rowKey={'id'}
          style={{ width: 1000 }}
          scroll={{ x: 1000, y: 300 }}
        />
      </CustomCard>
      {showModalSiteCodeDetail && (
        <ModalSiteCodeDetail
          selectedSiteCode={selectedSiteCode}
          showModal={showModalSiteCodeDetail}
          setShowModal={setShowModalSiteCodeDetail}
        />
      )}
    </>
  );

  if (initialData) {
    return TableComponent;
  }

  return (
    <CustomPage title={t('qrcode_list')} backButton={backButton}>
      {TableComponent}
    </CustomPage>
  );
};

export default PanelQRList;
