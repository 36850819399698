import { useTranslation } from 'react-i18next';
import { Button, Table, Modal } from 'antd';

import { safeJSONparse } from '@/utils/string';
import { useMemo } from 'react';

interface ModalSiteCodeDetailInterface {
  selectedSiteCode: any;
  showModal: boolean;
  setShowModal: (value: boolean) => void;
}

const ModalSiteCodeDetail = ({
  showModal,
  setShowModal,
  selectedSiteCode,
}: ModalSiteCodeDetailInterface) => {
  const { t } = useTranslation();

  const siteCodes = useMemo(
    () => safeJSONparse(selectedSiteCode, []),
    [selectedSiteCode]
  );

  const columns: any[] = [
    {
      title: t('number'),
      dataIndex: 'id',
      render: (_: any, __: any, index: number) => index + 1,
    },
    {
      title: t('site_code'),
      dataIndex: 'label',
    },
    {
      title: t('duration'),
      render: (_: any, record: any) => {
        return `${record.dateFrom.parsed}-${record.dateUntil.parsed}`;
      },
    },
  ];

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <Modal
      title={t('list_site_code')}
      visible={showModal}
      width={600}
      onCancel={handleCloseModal}
      footer={[
        <Button key={2} type="primary" onClick={handleCloseModal}>
          {t('ok')}
        </Button>,
      ]}
    >
      <Table
        key={'siteCode'}
        columns={columns}
        dataSource={siteCodes}
        rowKey={'id'}
      />
    </Modal>
  );
};

export default ModalSiteCodeDetail;
