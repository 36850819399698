import { useState, useCallback } from 'react';
import { Button, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { ButtonType } from 'antd/lib/button';
import { PrinterOutlined } from '@ant-design/icons';

import { useData } from '@/context';
import Message from '@/components/Message';

interface ButtonPrintInterface {
  ids: string[];
  type?: ButtonType;
  icon?: React.ReactNode;
  title?: string;
  callBack?: () => void;
}

const ButtonPrint = ({
  ids,
  type = 'primary',
  icon = <PrinterOutlined />,
  title = '',
  callBack,
}: ButtonPrintInterface) => {
  const { t } = useTranslation();
  const { onPostData } = useData();
  const [loading, setLoading] = useState(false);

  const resendQRCode = useCallback(async () => {
    setLoading(true);

    try {
      const result = await onPostData('qr-code/print', { ids });

      if (result.error) {
        Message.warning(t('print_qr_code_failed'));
        setLoading(false);
        return;
      }

      const success =
        result.success.count &&
        `${t('success')} ${t('print')} ${result.success.count} ${t('qr_code')}`;
      const failed =
        result.failed.count &&
        `${t('failure')} ${t('print')} ${result.failed.count} ${t('qr_code')}`;
      const messages = [failed, success]
        .filter((message) => message)
        .join(', ');

      if (result.failed.count) {
        await Message.warning(messages);
      } else {
        await Message.success(messages);
      }

      if (callBack) {
        callBack();
      }
    } catch (error) {
      Message.warning(t('print_qr_code_failed'));
      setLoading(false);
      return;
    } finally {
      setLoading(false);
    }
  }, [onPostData, ids, t, callBack]);

  return (
    <Tooltip title={t('print_qr_code')}>
      <Button
        type={type}
        icon={icon}
        size="middle"
        loading={loading}
        onClick={resendQRCode}
      >
        {title}
      </Button>
    </Tooltip>
  );
};

export default ButtonPrint;
