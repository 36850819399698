import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, Form, Input } from 'antd';

import layout from '@/utils/layout';
import { useAuth } from '@/context';
import { useData } from '@/context';
import CustomPage from '@/components/CustomPage';
import CustomForm from '@/components/CustomForm';
import CustomFormItem from '@/components/CustomFormItem';

const PanelLogin = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { onLogin } = useAuth();
  const { onPostData } = useData();
  const [loading, setLoading] = useState<boolean>(false);
  const [skeleton] = useState<boolean>(false);

  const onFinish = async (value: any) => {
    setLoading(true);

    const payload = {
      username: value.username,
      password: value.password,
    };

    const result = await onPostData('auth', payload);

    onLogin(result);
    setLoading(false);
  };

  return (
    <CustomPage title={t('login')} backButton={[<></>]}>
      <CustomForm
        form={form}
        skeleton={skeleton}
        onFinish={onFinish}
        col={layout.form}
        style={{ width: 550 }}
        actions={[
          <Form.Item shouldUpdate style={{ marginRight: 50, marginLeft: 50 }}>
            {() => (
              <Button
                key="submit"
                size="large"
                type="primary"
                htmlType="submit"
                loading={loading}
                style={{ width: 500 }}
                disabled={
                  form.getFieldsError().filter(({ errors }) => errors.length)
                    .length > 0
                }
              >
                {t('login')}
              </Button>
            )}
          </Form.Item>,
        ]}
      >
        <Card>
          <CustomFormItem
            name="username"
            skeleton={skeleton}
            label={t('user_name')}
            rules={[{ required: true, message: t('complete_this_field') }]}
            labelCol={{ lg: { span: 8 } }}
          >
            <Input placeholder={t('input_user_name')} />
          </CustomFormItem>
          <CustomFormItem
            name="password"
            skeleton={skeleton}
            label={t('password')}
            rules={[
              {
                required: true,
                message: t('complete_this_field'),
              },
            ]}
            labelCol={{ lg: { span: 8 } }}
          >
            <Input.Password placeholder={t('input_password')} />
          </CustomFormItem>
        </Card>
      </CustomForm>
    </CustomPage>
  );
};

export default PanelLogin;
