import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Card, Input, Button } from 'antd';

import CustomPage from '@/components/CustomPage';
import CustomForm from '@/components/CustomForm';
import CustomFormItem from '@/components/CustomFormItem';
import Message from '@/components/Message';

import layout from '@/utils/layout';
import { useData } from '@/context';
import ModalSearchPrinter from '@/components/ModalSearchPrinter';

const PanelSettingsPrinter = () => {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const { onFetchData, onPostData } = useData();
  const [loading, setLoading] = useState<boolean>(false);
  const [skeleton, setSkeleton] = useState<boolean>(false);
  const [showModal, setShowModal] = useState(false);
  const [printerName, setPrinterName] = useState('');

  const onFinish = async (value: any) => {
    setLoading(true);

    const payload = { name: printerName || value.name };
    const res = await onPostData('setting/printer', payload);

    if (res.error) {
      Message.warning(t('apply_printer_configuration_failed'));
      setLoading(false);
      return;
    }

    await Message.success(t('success'));
    setLoading(false);
  };

  useEffect(() => {
    form.setFieldsValue({ name: printerName });
  }, [form, printerName]);

  useEffect(() => {
    async function initial() {
      setSkeleton(true);

      const res = await onFetchData('setting/printer');

      if (res.error) {
        setLoading(false);
        setSkeleton(false);
        return;
      }

      if (res.data) {
        form.setFieldsValue({ name: res.data.name });
      }

      setSkeleton(false);
      setLoading(false);
    }

    initial();
  }, [form, onFetchData, t]);

  return (
    <CustomPage title={t('printer')} backButton={[<></>]}>
      <CustomForm
        form={form}
        skeleton={skeleton}
        onFinish={onFinish}
        col={layout.form}
        style={{ width: 550 }}
        actions={[
          <Button
            key="submit"
            size="large"
            type="primary"
            htmlType="submit"
            loading={loading}
            style={{ width: 'calc(100% - 48px)' }}
          >
            {t('apply')}
          </Button>,
        ]}
      >
        <Card>
          <CustomFormItem
            name="name"
            skeleton={skeleton}
            label={t('printer_name')}
            rules={[{ required: true, message: t('complete_this_field') }]}
            labelCol={{ lg: { span: 11 } }}
          >
            <Input placeholder={t('printer_name')} />
          </CustomFormItem>
        </Card>
      </CustomForm>
      {showModal && (
        <ModalSearchPrinter
          showModal={showModal}
          setShowModal={setShowModal}
          setPrinterName={setPrinterName}
        />
      )}
    </CustomPage>
  );
};

export default PanelSettingsPrinter;
