import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Card, Input, Button } from 'antd';

import CustomPage from '@/components/CustomPage';
import CustomForm from '@/components/CustomForm';
import CustomFormItem from '@/components/CustomFormItem';
import Message from '@/components/Message';

import layout from '@/utils/layout';
import { useData } from '@/context';

const PanelSettingsFirebase = () => {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const { onFetchData, onPostData } = useData();
  const [loading, setLoading] = useState<boolean>(false);
  const [skeleton, setSkeleton] = useState<boolean>(false);

  const initial = useCallback(async () => {
    setSkeleton(true);

    const res = await onFetchData('setting/system');

    if (res.error) {
      setLoading(false);
      setSkeleton(false);
      return;
    }

    if (res.data) {
      form.setFieldsValue({
        firebase_url: res.data.firebase_url,
        firebase_hosting: res.data.firebase_hosting,
      });
    }

    setSkeleton(false);
    setLoading(false);
  }, [form, onFetchData]);

  const onFinish = async (value: any) => {
    setLoading(true);

    const payload = {
      firebase_url: value.firebase_url,
      firebase_hosting: value.firebase_hosting,
    };

    const res = await onPostData('setting/system', payload);

    if (res.error) {
      Message.warning(t('apply_system_configuration_failed'));
      setLoading(false);
      return;
    }

    await Message.success(t('success'));

    window.location.href = '/';

    setLoading(false);
  };

  useEffect(() => {
    initial();
  }, [initial]);

  return (
    <CustomPage title={t('firebase')} backButton={[<></>]}>
      <CustomForm
        form={form}
        skeleton={skeleton}
        onFinish={onFinish}
        col={layout.form}
        style={{ width: 550 }}
        actions={[
          <Button
            key="submit"
            size="large"
            type="primary"
            htmlType="submit"
            loading={loading}
            style={{ width: 'calc(100% - 48px)' }}
          >
            {t('apply')}
          </Button>,
        ]}
      >
        <Card>
          <CustomFormItem
            name="firebase_url"
            skeleton={skeleton}
            label={t('firebase_function_url')}
            labelCol={{ lg: { span: 11 } }}
            rules={[{ required: true, message: t('complete_this_field') }]}
          >
            <Input placeholder={t('firebase_function_url')} />
          </CustomFormItem>

          <CustomFormItem
            name="firebase_hosting"
            skeleton={skeleton}
            label={t('firebase_hosting_url')}
            labelCol={{ lg: { span: 11 } }}
            rules={[{ required: true, message: t('complete_this_field') }]}
          >
            <Input placeholder={t('firebase_hosting_url')} />
          </CustomFormItem>
        </Card>
      </CustomForm>
    </CustomPage>
  );
};

export default PanelSettingsFirebase;
