import { ReactNode } from 'react';
import { Card, Skeleton } from 'antd';

interface CustomCardInterface {
  style?: Record<string, any>;
  children?: ReactNode;
  skeleton?: boolean;
  bordered?: boolean;
  actions?: ReactNode[];
}

const CustomCard = ({
  style,
  children,
  skeleton,
  bordered,
  actions = [],
}: CustomCardInterface) => {
  return (
    <Card
      bordered={bordered}
      actions={
        skeleton && actions.length > 0
          ? [<Skeleton.Button active style={{ width: 500 }} size="default" />]
          : actions
      }
    >
      <div
        style={{
          maxWidth: 800,
          ...style,
          overflow: 'auto',
        }}
      >
        {children}
      </div>
    </Card>
  );
};

export default CustomCard;
