import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import CustomFormItem from '@/components/CustomFormItem';
import { useGlobal } from '@/context';

interface SelectSiteCodeInterface {
  name?: string;
  noLabel?: boolean;
  onChange?: (value: any, option: any) => void;
  style?: any;
}

const SelectSiteCode = ({
  name,
  noLabel,
  onChange,
  style,
}: SelectSiteCodeInterface) => {
  const { t } = useTranslation();
  const { siteCodes } = useGlobal();

  return (
    <CustomFormItem
      name={name || 'qrcodeSiteCode'}
      label={!noLabel && t('qrcode_site_code')}
      labelCol={{ lg: { span: 8 } }}
      rules={[{ required: true, message: t('complete_this_field') }]}
      style={style || {}}
    >
      <Select
        style={{ width: '100%' }}
        placeholder={t('site_code')}
        onChange={onChange}
      >
        {siteCodes.map((item: any, index: number) => (
          <Select.Option key={index} value={item.id}>
            {item.label}
          </Select.Option>
        ))}
      </Select>
    </CustomFormItem>
  );
};

export default SelectSiteCode;
