import { Redirect } from 'react-router';
import { GuardInterface } from '@/interfaces/guard';
import { useGlobal } from '@/context';

export const HasLicenseGuard = ({ children }: GuardInterface) => {
  const { license, loadingLicense } = useGlobal();

  if (loadingLicense) return null;

  if (!loadingLicense && license)
    return <Redirect to={{ pathname: '/' }}></Redirect>;

  return <>{children}</>;
};
