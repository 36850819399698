import { useState } from 'react';
import { Table, Modal, Space, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useData } from '@/context';
import CustomCard from '@/components/CustomCard';
import Message from '@/components/Message';

interface PanelSiteCodeListInterface {
  data: any;
  refetch: () => void;
}

const PanelSiteCodeList = ({ data, refetch }: PanelSiteCodeListInterface) => {
  const [page, setPage] = useState<number>(1);
  const { t } = useTranslation();
  const { onDeleteData } = useData();
  const [loading, setLoading] = useState<boolean>(false);

  const handleDelete = async (id: string) => {
    setLoading(true);
    const res = await onDeleteData(`sitecode/${id}`);

    if (res.error) {
      Message.warning(t('delete_sitecode_failed'));
      setLoading(false);
      return;
    }

    Modal.destroyAll();
    refetch();
    setLoading(false);
    await Message.success(t('success'));
  };

  const onClickDelete = (id: string) => {
    Modal.confirm({
      title: t('confirm_delete'),
      content: t('confirm_delete_message'),
      onCancel: () => {},
      onOk: () => handleDelete(id),
    });
  };

  const columns: any[] = [
    {
      title: t('number'),
      dataIndex: 'id',
      width: 90,
      render: (_: any, __: any, index: number) => (page - 1) * 10 + (index + 1),
    },
    {
      title: t('site_code'),
      dataIndex: 'code',
      width: 130,
    },
    {
      title: t('site_label'),
      dataIndex: 'label',
      width: 130,
    },
    {
      title: t('action'),
      dataIndex: 'action',
      render: (_: string, record: Record<string, string>) => (
        <Space size="middle">
          <Link
            to={{
              pathname: `/panel/sitecode/${record.code}`,
              state: { prevPath: window.location.pathname },
            }}
          >
            <Button>{t('edit')}</Button>
          </Link>
          <Button onClick={() => onClickDelete(record.code)}>
            {t('delete')}
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <CustomCard>
      <Table
        key={'sitecode'}
        columns={columns}
        dataSource={data}
        loading={loading}
        rowKey={'id'}
        style={{ width: 550 }}
        scroll={{ x: 550, y: 300 }}
        pagination={{
          onChange(current) {
            setPage(current);
          },
        }}
      />
    </CustomCard>
  );
};

export default PanelSiteCodeList;
