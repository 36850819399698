import { useTranslation } from 'react-i18next';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useState, useEffect, useRef, useMemo } from 'react';
import { Form, Card, Input, Button, Tooltip, Switch } from 'antd';
// import { PasswordInput } from 'antd-password-input-strength';

import layout from '@/utils/layout';
import { useData } from '@/context';
import Message from '@/components/Message';
// import { passwordRegex } from '@/utils/regex';
import CustomPage from '@/components/CustomPage';
import CustomForm from '@/components/CustomForm';
import InputSchedule from '@/components/InputSchedule';
import CustomFormItem from '@/components/CustomFormItem';

const PanelSettingsQRBackup = () => {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const { onFetchData, onPostData } = useData();
  const [loading, setLoading] = useState<boolean>(false);
  const [skeleton, setSkeleton] = useState<boolean>(false);
  const [backup, setBackup] = useState<boolean>(true);
  const ref = useRef<HTMLInputElement>(null);

  const onFinish = async (value: any) => {
    setLoading(true);

    const res = await onPostData('setting/qr_backup', value);

    if (res.error) {
      Message.warning(t('apply_backup_configuration_failed'));
      setLoading(false);
      return;
    }

    await Message.success(t('success'));
    setLoading(false);
  };

  const defaultChecked = useMemo(() => backup, [backup]);

  const onChangeSwitch = async (value: boolean) => {
    setBackup(value);
  };

  useEffect(() => {
    form.setFieldsValue({});
  }, [form]);

  useEffect(() => {
    async function initial() {
      setSkeleton(true);

      const res = await onFetchData('setting/qr_backup');

      if (res.error) {
        Message.warning(t('apply_backup_configuration_failed'));
        setLoading(false);
        setSkeleton(false);
        return;
      }

      if (res.data) {
        const isDate = res.data.backup === 'true';

        setBackup(isDate);
        form.setFieldsValue({
          backup: isDate,
          destination: res.data.destination,
          duration: res.data.duration,
          every: res.data.every,
          password: res.data.password,
        });
      }

      setSkeleton(false);
      setLoading(false);
    }

    initial();
  }, [form, onFetchData, t]);

  useEffect(() => {
    if (ref.current !== null) {
      ref.current.setAttribute('directory', '');
      ref.current.setAttribute('webkitdirectory', '');
    }
  }, [ref]);

  return (
    <CustomPage title={t('qr_backup')} backButton={[<></>]}>
      <CustomForm
        form={form}
        skeleton={skeleton}
        onFinish={onFinish}
        col={layout.form}
        style={{ width: 550 }}
        actions={[
          <Button
            key="submit"
            size="large"
            type="primary"
            htmlType="submit"
            loading={loading}
            style={{ width: 'calc(100% - 48px)' }}
          >
            {t('apply')}
          </Button>,
        ]}
      >
        <Card>
          <CustomFormItem
            name="backup"
            skeleton={skeleton}
            label={t('backup')}
            rules={[{ required: true, message: t('complete_this_field') }]}
            labelCol={{ lg: { span: 11 } }}
          >
            <Switch
              checkedChildren={t('on')}
              unCheckedChildren={t('off')}
              onChange={onChangeSwitch}
              defaultChecked={defaultChecked}
            />
          </CustomFormItem>

          <CustomFormItem
            name="destination"
            skeleton={skeleton}
            label={t('destination_folder')}
            rules={[{ required: true, message: t('complete_this_field') }]}
            labelCol={{ lg: { span: 11 } }}
          >
            <Input
              disabled={!backup}
              placeholder={t('destination_folder')}
              suffix={
                <Tooltip title={t('info_destination')}>
                  <InfoCircleOutlined />
                </Tooltip>
              }
            />
          </CustomFormItem>

          <InputSchedule disabled={!backup} />

          {/* <CustomFormItem
            name="password"
            skeleton={skeleton}
            label={t('password')}
            rules={[
              {
                required: true,
                message: t('complete_this_field'),
              },
              {
                required: true,
                pattern: new RegExp(passwordRegex),
                message: t('password_strength'),
              },
            ]}
            labelCol={{ lg: { span: 11 } }}
          >
            <PasswordInput placeholder={t('input_password')} />
          </CustomFormItem> */}
        </Card>
      </CustomForm>
    </CustomPage>
  );
};

export default PanelSettingsQRBackup;
