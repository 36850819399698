import { useState, useCallback } from 'react';
import { Button, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { ButtonType } from 'antd/lib/button';
import { DownloadOutlined } from '@ant-design/icons';

import { useData } from '@/context';
import Message from '@/components/Message';

interface ButtonDownloadInterface {
  ids: string[];
  type?: ButtonType;
  icon?: React.ReactNode;
  title?: string;
  callBack?: () => void;
}

const ButtonDownload = ({
  ids,
  type = 'primary',
  icon = <DownloadOutlined />,
  title = '',
  callBack,
}: ButtonDownloadInterface) => {
  const { t } = useTranslation();
  const { onPostData } = useData();
  const [loading, setLoading] = useState(false);

  const downloadImage = (data: any) => {
    for (var i in data) {
      var a = document.createElement('a');
      a.href = data[i].qrImage;
      a.download = `${data[i].id}.png`;
      a.click();
    }
  };

  const downloadQRCode = useCallback(async () => {
    setLoading(true);

    try {
      const result = await onPostData('qr-code/download', {
        ids,
        isBrowser: true,
      });

      if (result.error) {
        Message.warning(t('qrcode_input_configuration_failed'));
        setLoading(false);
        return;
      }

      console.log('here result.data = ', result.data);

      downloadImage(result.data);

      await Message.success(
        `${t('success')} ${t('download')} ${result.data.length}`
      );

      if (callBack) {
        callBack();
      }
    } catch (error) {
      Message.warning(t('qrcode_input_configuration_failed'));
      setLoading(false);
      return;
    } finally {
      setLoading(false);
    }
  }, [onPostData, ids, t, callBack]);

  return (
    <Tooltip title={t('download_qr_code')}>
      <Button
        type={type}
        icon={icon}
        size="middle"
        loading={loading}
        onClick={downloadQRCode}
      >
        {title}
      </Button>
    </Tooltip>
  );
};

export default ButtonDownload;
