import { InputNumber, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import CustomFormItem from '@/components/CustomFormItem';

interface InputScheduleInterface {
  disabled: boolean;
}

const InputSchedule = ({ disabled }: InputScheduleInterface) => {
  const { t } = useTranslation();

  return (
    <>
      <CustomFormItem
        name="duration"
        label={t('deletion')}
        rules={[{ required: true, message: t('complete_this_field') }]}
        labelCol={{ lg: { span: 11 } }}
      >
        <InputNumber disabled={disabled} placeholder={t('duration')} />
      </CustomFormItem>
      <CustomFormItem
        name="every"
        label={t('every')}
        rules={[{ required: true, message: t('complete_this_field') }]}
        labelCol={{ lg: { span: 11 } }}
      >
        <Select disabled={disabled}>
          <Select.Option value="minutes">{t('minutes')}</Select.Option>
          <Select.Option value="hours">{t('hours')}</Select.Option>
          <Select.Option value="days">{t('days')}</Select.Option>
        </Select>
      </CustomFormItem>
    </>
  );
};

export default InputSchedule;
