import './index.less';
import { Layout, Row, Col, Button } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { useAuth, useLang } from '@/context';
import logo from '@/assets/biostar-2.png';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

const Header = () => {
  const { changeLang, lang } = useLang();
  const { t } = useTranslation();
  const { hasToken, onLogout } = useAuth();

  const handleLogout = () => {
    onLogout();
  };

  return (
    <Layout.Header>
      <Row>
        <Col span={12} className="text-align-left">
          <img src={logo} alt="logo" />
        </Col>
        <Col span={12} className="text-align-right">
          <Select
            style={{ width: '70px', marginRight: '10px' }}
            placeholder={t('lang')}
            defaultValue={lang}
            onChange={(value: string) => changeLang(value)}
          >
            <Select.Option value="en">EN</Select.Option>
            <Select.Option value="jp">JP</Select.Option>
          </Select>

          {hasToken && (
            <Button
              type="text"
              icon={<LogoutOutlined style={{ fontSize: 24 }} />}
              onClick={handleLogout}
            />
          )}
        </Col>
      </Row>
    </Layout.Header>
  );
};

export default Header;
