import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input } from 'antd';

import CustomForm from '@/components/CustomForm';
import CustomFormItem from '@/components/CustomFormItem';
import Message from '@/components/Message';
import layout from '@/utils/layout';
import { useData } from '@/context';

const RequestLicense = () => {
  const { t } = useTranslation();
  const [requestLicenseForm] = Form.useForm();
  const { onFetchData } = useData();
  const [loading, setLoading] = useState<boolean>(false);

  const downloadReqOfflineLicense = (reqOfflineLicensePayload: any) => {
    const data = new Blob([JSON.stringify(reqOfflineLicensePayload)], {
      type: 'text',
    });
    const dataUrl = window.URL.createObjectURL(data);
    const tempLink = document.createElement('a');
    tempLink.href = dataUrl;
    tempLink.setAttribute('download', 'license.req');
    tempLink.click();
    tempLink.remove();
  };

  const onReqOfflineLicense = async () => {
    try {
      await requestLicenseForm.validateFields();
    } catch (error) {
      return;
    }

    setLoading(true);
    const formValue = requestLicenseForm.getFieldsValue();
    const res = await onFetchData('license/request', {
      company: formValue.companyName,
    });

    if (res.error) {
      Message.warning(t('request_license_failed'));
      return setLoading(false);
    }

    downloadReqOfflineLicense(res.data);
    requestLicenseForm.resetFields();
    await Message.success(t('successfully_license_request'));
    setLoading(false);
  };

  return (
    <CustomForm
      col={layout.form}
      form={requestLicenseForm}
      onFinish={onReqOfflineLicense}
      name="request-offline-license"
    >
      <CustomFormItem
        name="companyName"
        label={t('company_name')}
        rules={[{ required: true, message: t('complete_this_field') }]}
      >
        <Input placeholder={t('company_name')} />
      </CustomFormItem>

      <Button
        type="primary"
        htmlType="submit"
        loading={loading}
        style={{ width: '100%' }}
      >
        {t('request_offline_license')}
      </Button>
    </CustomForm>
  );
};

export default RequestLicense;
