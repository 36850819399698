import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input } from 'antd';

import CustomForm from '@/components/CustomForm';
import CustomFormItem from '@/components/CustomFormItem';
import Message from '@/components/Message';
import layout from '@/utils/layout';
import { useData } from '@/context';

const ActivateLicense = () => {
  const { t } = useTranslation();
  const [activationForm] = Form.useForm();
  const [requestLicenseForm] = Form.useForm();
  const { onPostData } = useData();
  const [loading, setLoading] = useState<boolean>(false);

  const onActivationSuccess = () => {
    window.location.href = '/';
  };

  const activateLicense = async () => {
    try {
      await activationForm.validateFields();
    } catch (error) {
      return;
    }

    setLoading(true);
    const licenseField = activationForm.getFieldInstance('license') as Input;
    const licenseFile = licenseField.input.files![0];

    const data = new FormData();
    data.append('license', licenseFile, 'license.lic');

    const result = await onPostData('license/activate', data);

    if (result.error) {
      Message.warning(t('license_activation_failed'));
      return setLoading(false);
    }

    requestLicenseForm.resetFields();
    setLoading(false);
    await Message.success(t('successfully_activate_license'));

    onActivationSuccess();
  };

  return (
    <CustomForm
      name="activation"
      col={layout.form}
      form={activationForm}
      onFinish={activateLicense}
    >
      <CustomFormItem
        name="license"
        label={t('choose_license')}
        rules={[{ required: true, message: t('complete_this_field') }]}
      >
        <Input type="file" accept=".lic" />
      </CustomFormItem>

      <Button
        type="primary"
        htmlType="submit"
        loading={loading}
        style={{ width: '100%' }}
      >
        {t('activate')}
      </Button>
    </CustomForm>
  );
};

export default ActivateLicense;
