import { useState } from 'react';
import { Table, Modal, Space, Button, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useData, useAuth } from '@/context';
import CustomCard from '@/components/CustomCard';
import Message from '@/components/Message';
import { InfoCircleOutlined, UserOutlined } from '@ant-design/icons';
import Role from '@/interfaces/role';

interface PanelUserListInterface {
  data: any;
  refetch: () => void;
}

const PanelUserList = ({ data, refetch }: PanelUserListInterface) => {
  const [page, setPage] = useState<number>(1);
  const { t } = useTranslation();
  const { onDeleteData } = useData();
  const [loading, setLoading] = useState<boolean>(false);
  const { logId } = useAuth();

  const handleDelete = async (id: string) => {
    setLoading(true);
    const res = await onDeleteData(`user/${id}`);

    if (res.error) {
      Message.warning(t('delete_user_failed'));
      setLoading(false);
      return;
    }

    Modal.destroyAll();
    refetch();
    setLoading(false);
    await Message.success(t('success'));
  };

  const onClickDelete = (id: string) => {
    Modal.confirm({
      title: t('confirm_delete'),
      content: t('confirm_delete_message'),
      onCancel: () => {},
      onOk: () => handleDelete(id),
    });
  };

  const columns: any[] = [
    {
      title: t('number'),
      dataIndex: 'id',
      width: 90,
      render: (_: any, __: any, index: number) => (page - 1) * 10 + (index + 1),
    },
    {
      title: t('user_name'),
      dataIndex: 'username',
      width: 130,
      render: (name: string, record: Record<string, string>) => {
        return (
          <div>
            {logId === record.id ? (
              <Tooltip title={t('tooltip_current_login')} trigger="click">
                {name}&nbsp;
                <UserOutlined />
              </Tooltip>
            ) : (
              name
            )}
          </div>
        );
      },
    },
    {
      title: t('user_role'),
      dataIndex: 'role',
      width: 130,
    },
    {
      title: t('action'),
      dataIndex: 'action',
      render: (_: string, record: Record<string, string>) => {
        const isCurrentLogin = logId === record.id;

        if (record.role === Role.Admin && record.username === Role.Admin) {
          if (!isCurrentLogin) {
            return (
              <Tooltip title={t('tooltip_superadmin')} trigger="click">
                <InfoCircleOutlined />
              </Tooltip>
            );
          } else {
            <Link
              to={{
                pathname: `/panel/user/${record.id}`,
                state: { prevPath: window.location.pathname },
              }}
            >
              <Button>{t('edit')}</Button>
            </Link>;
          }
        }

        return (
          <Space size="middle">
            <Link
              to={{
                pathname: `/panel/user/${record.id}`,
                state: { prevPath: window.location.pathname },
              }}
            >
              <Button>{t('edit')}</Button>
            </Link>
            {!isCurrentLogin && (
              <Button onClick={() => onClickDelete(record.id)}>
                {t('delete')}
              </Button>
            )}
          </Space>
        );
      },
    },
  ];

  return (
    <CustomCard>
      <Table
        key={'user'}
        columns={columns}
        dataSource={data}
        loading={loading}
        rowKey={'id'}
        style={{ width: 550 }}
        scroll={{ x: 550, y: 300 }}
        pagination={{
          onChange(current) {
            setPage(current);
          },
        }}
      />
    </CustomCard>
  );
};

export default PanelUserList;
