import { Redirect } from 'react-router';
import { useAuth } from '@/context';
import { GuardInterface } from '@/interfaces/guard';

export const RoleAdminGuard = ({ children }: GuardInterface) => {
  const { isAdmin, isSub } = useAuth();

  const allow = isAdmin || isSub;

  if (!allow) return <Redirect to={{ pathname: '/panel' }}></Redirect>;

  return <>{children}</>;
};
