import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Card, Input, InputNumber, Button } from 'antd';

import CustomPage from '@/components/CustomPage';
import CustomForm from '@/components/CustomForm';
import CustomFormItem from '@/components/CustomFormItem';
import Message from '@/components/Message';

import layout from '@/utils/layout';
import { useData } from '@/context';
import { RuleObject } from 'antd/lib/form';
import { StoreValue } from 'antd/lib/form/interface';
import { emailRegex, smtpRegex } from '@/utils/regex';

const PanelSettings = () => {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const { onFetchData, onPostData } = useData();
  const [loading, setLoading] = useState<boolean>(false);
  const [skeleton, setSkeleton] = useState<boolean>(false);

  const emailValidator = (
    _: RuleObject,
    value: StoreValue,
    callback: (error?: string) => void
  ) => {
    const validInput = Boolean(value.match(emailRegex));

    if (validInput) {
      callback();
    } else {
      callback(t('invalid_email'));
    }
  };

  const smtpValidator = (
    _: RuleObject,
    value: StoreValue,
    callback: (error?: string) => void
  ) => {
    const validInput = Boolean(value.match(smtpRegex));

    if (validInput) {
      callback();
    } else {
      callback(t('invalid_smtp_host'));
    }
  };

  const onFinish = async (value: any) => {
    setLoading(true);

    const payload = {
      host: value.smtpHost,
      port: value.smtpPort,
      username: value.smtpUsername,
      password: value.smtpPassword,
      sender: value.smtpSender,
      senderName: value.smtpSenderName,
    };
    const res = await onPostData('setting/smtp', payload);

    if (res.error) {
      const message = res.message || t('apply_smtp_configuration_failed');

      Message.warning(message);
      setLoading(false);
      return;
    }

    await Message.success(t('success'));
    setLoading(false);
  };

  useEffect(() => {
    async function initial() {
      setSkeleton(true);

      const res = await onFetchData('setting/smtp');

      if (res.error) {
        setLoading(false);
        setSkeleton(false);
        return;
      }

      if (res.data) {
        form.setFieldsValue({
          smtpSenderName: res.data.senderName,
          smtpSender: res.data.sender,
          smtpUsername: res.data.username,
          smtpPassword: res.data.password,
          smtpHost: res.data.host,
          smtpPort: res.data.port,
        });
      }

      setSkeleton(false);
      setLoading(false);
    }

    initial();
  }, [form, onFetchData, t]);

  return (
    <CustomPage title={t('smtp')} backButton={[<></>]}>
      <CustomForm
        form={form}
        skeleton={skeleton}
        onFinish={onFinish}
        col={layout.form}
        style={{ width: 550 }}
        actions={[
          <Button
            key="submit"
            size="large"
            type="primary"
            htmlType="submit"
            loading={loading}
            style={{ width: 'calc(100% - 48px)' }}
          >
            {t('apply')}
          </Button>,
        ]}
      >
        <Card>
          <CustomFormItem
            name="smtpSenderName"
            skeleton={skeleton}
            label={t('smtp_sender_name')}
            rules={[{ required: true, message: t('complete_this_field') }]}
            labelCol={{ lg: { span: 11 } }}
          >
            <Input placeholder={t('smtp_sender_name')} />
          </CustomFormItem>

          <CustomFormItem
            name="smtpSender"
            skeleton={skeleton}
            label={t('smtp_sender')}
            rules={[
              { required: true, message: t('complete_this_field') },
              {
                validator: emailValidator,
              },
            ]}
            labelCol={{ lg: { span: 11 } }}
          >
            <Input placeholder={t('smtp_sender')} />
          </CustomFormItem>

          <CustomFormItem
            name="smtpUsername"
            skeleton={skeleton}
            label={t('smtp_username')}
            rules={[
              { required: true, message: t('complete_this_field') },
              {
                validator: emailValidator,
              },
            ]}
            labelCol={{ lg: { span: 11 } }}
          >
            <Input placeholder={t('smtp_username')} />
          </CustomFormItem>

          <CustomFormItem
            name="smtpPassword"
            skeleton={skeleton}
            label={t('smtp_password')}
            rules={[{ required: true, message: t('complete_this_field') }]}
            labelCol={{ lg: { span: 11 } }}
          >
            <Input.Password placeholder={t('smtp_password')} />
          </CustomFormItem>

          <CustomFormItem
            name="smtpHost"
            skeleton={skeleton}
            label={t('smtp_host')}
            rules={[
              { required: true, message: t('complete_this_field') },
              {
                validator: smtpValidator,
              },
            ]}
            labelCol={{ lg: { span: 11 } }}
          >
            <Input placeholder={t('smtp_host')} />
          </CustomFormItem>

          <CustomFormItem
            name="smtpPort"
            skeleton={skeleton}
            label={t('smtp_port')}
            rules={[{ required: true, message: t('complete_this_field') }]}
            labelCol={{ lg: { span: 11 } }}
          >
            <InputNumber
              type="number"
              precision={0}
              placeholder={t('smtp_port')}
            />
          </CustomFormItem>
        </Card>
      </CustomForm>
    </CustomPage>
  );
};

export default PanelSettings;
