import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Card, Input, Button } from 'antd';

import CustomPage from '@/components/CustomPage';
import CustomForm from '@/components/CustomForm';
import CustomFormItem from '@/components/CustomFormItem';
import Message from '@/components/Message';

import layout from '@/utils/layout';
import { useData } from '@/context';

const PanelSettings = () => {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const { onFetchData, onPostData } = useData();
  const [loading, setLoading] = useState<boolean>(false);
  const [skeleton, setSkeleton] = useState<boolean>(false);

  const onFinish = async (value: any) => {
    setLoading(true);

    const res = await onPostData('setting/email_template', value);

    if (res.error) {
      Message.warning(t('apply_email_template_failed'));
      setLoading(false);
      return;
    }

    await Message.success(t('success'));
    setLoading(false);
  };

  useEffect(() => {
    async function initial() {
      setSkeleton(true);

      const res = await onFetchData('setting/email_template');

      if (res.error) {
        setLoading(false);
        setSkeleton(false);
        return;
      }

      if (res.data) {
        form.setFieldsValue({
          subject: res.data.subject,
          header: res.data.header,
          body: res.data.body,
          footer: res.data.footer,
          style: res.data.style,
        });
      }

      setSkeleton(false);
      setLoading(false);
    }

    initial();
  }, [form, onFetchData, t]);

  return (
    <CustomPage title={t('email_template')} backButton={[<></>]}>
      <CustomForm
        form={form}
        skeleton={skeleton}
        onFinish={onFinish}
        col={layout.form}
        style={{ width: 550 }}
        actions={[
          <Button
            key="submit"
            size="large"
            type="primary"
            htmlType="submit"
            loading={loading}
            style={{ width: 'calc(100% - 48px)' }}
          >
            {t('apply')}
          </Button>,
        ]}
      >
        <Card>
          <CustomFormItem
            name="subject"
            skeleton={skeleton}
            label={t('email_subject')}
            rules={[{ message: t('complete_this_field') }]}
            labelCol={{ lg: { span: 11 } }}
          >
            <Input placeholder={t('email_subject')} />
          </CustomFormItem>

          <CustomFormItem
            name="header"
            skeleton={skeleton}
            label={t('email_header')}
            rules={[{ message: t('complete_this_field') }]}
            labelCol={{ lg: { span: 11 } }}
          >
            <Input.TextArea placeholder={t('email_header')} />
          </CustomFormItem>

          <CustomFormItem
            name="body"
            skeleton={skeleton}
            label={t('email_body')}
            rules={[{ message: t('complete_this_field') }]}
            labelCol={{ lg: { span: 11 } }}
          >
            <Input.TextArea placeholder={t('email_body')} />
          </CustomFormItem>

          <CustomFormItem
            name="footer"
            skeleton={skeleton}
            label={t('email_footer')}
            rules={[{ message: t('complete_this_field') }]}
            labelCol={{ lg: { span: 11 } }}
          >
            <Input.TextArea placeholder={t('email_footer')} />
          </CustomFormItem>

          <CustomFormItem
            name="style"
            skeleton={skeleton}
            label={t('email_style')}
            rules={[{ message: t('complete_this_field') }]}
            labelCol={{ lg: { span: 11 } }}
          >
            <Input.TextArea placeholder={t('email_style')} />
          </CustomFormItem>
        </Card>
      </CustomForm>
    </CustomPage>
  );
};

export default PanelSettings;
