import { Form, Skeleton } from 'antd';
import { ReactNode } from 'react';
import { Rule } from 'rc-field-form/lib/interface';
import { ColProps } from 'antd/lib/grid/col';

interface CustomFormItemInterface {
  name?: string;
  label?: ReactNode;
  layout?: Record<string, string>;
  noStyle?: boolean;
  children?: ReactNode;
  skeleton?: boolean;
  rules?: Rule[];
  wrapperCol?: ColProps;
  labelCol?: ColProps;
  valuePropName?: string;
  style?: Record<string, string>;
  className?: string;
}

const CustomFormItem = ({
  name,
  label,
  layout,
  noStyle,
  children,
  skeleton,
  rules = [],
  wrapperCol,
  labelCol,
  valuePropName,
  style,
  className,
}: CustomFormItemInterface) => {
  return (
    <Form.Item
      className={className}
      {...layout}
      name={name}
      rules={rules}
      noStyle={noStyle}
      label={
        skeleton ? (
          <Skeleton.Input active style={{ width: 100 }} size="default" />
        ) : (
          label
        )
      }
      wrapperCol={wrapperCol}
      labelCol={labelCol}
      valuePropName={valuePropName}
      style={style}
    >
      {skeleton ? (
        <Skeleton.Input active style={{ width: 300 }} size="default" />
      ) : (
        children
      )}
    </Form.Item>
  );
};

export default CustomFormItem;
