import { AxiosInstance } from 'axios';
import { IApiResponse } from '@/interfaces/apiResponse';

const getAxios = async (
  instance: AxiosInstance,
  url: string,
  params?: Record<string, any>
): Promise<IApiResponse> => {
  return instance
    .get(url, { params })
    .then((resp) => ({ data: resp.data, meta: { status: resp.status } }))
    .catch((error) => ({
      error: error.response?.data,
      meta: { status: error.response ? error.response.status : 500 },
    }));
};

const postAxios = async (
  instance: AxiosInstance,
  url: string,
  data?: any
): Promise<IApiResponse> => {
  return instance
    .post(url, data)
    .then((resp) => ({ ...resp.data, meta: { status: resp.status } }))
    .catch((error) => ({
      ...error.response?.data,
      meta: { status: error?.response?.status || 0 },
    }));
};

const putAxios = async (
  instance: AxiosInstance,
  url: string,
  data?: any
): Promise<IApiResponse> => {
  return instance
    .put(url, data)
    .then((resp) => ({ ...resp.data, meta: { status: resp.status } }))
    .catch((error) => ({
      ...error.response?.data,
      meta: { status: error?.response?.status || 0 },
    }));
};

const deleteAxios = async (
  instance: AxiosInstance,
  url: string,
  data?: any
): Promise<IApiResponse> => {
  return instance
    .delete(url, data)
    .then((resp) => ({ ...resp.data, meta: { status: resp.status } }))
    .catch((error) => ({
      ...error.response?.data,
      meta: { status: error?.response?.status || 0 },
    }));
};

const api = { getAxios, postAxios, putAxios, deleteAxios };

export default api;
