import { useState, useCallback } from 'react';
import { Button, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { ButtonType } from 'antd/lib/button';
import { StopOutlined } from '@ant-design/icons';

import { useData } from '@/context';
import Message from '@/components/Message';

interface ButtonRejectQRCodeInterface {
  ids: Record<string, any>[];
  type?: ButtonType;
  icon?: React.ReactNode;
  title?: string;
  disabled?: boolean;
  callBack?: () => void;
}

const ButtonRejectQRCode = ({
  ids,
  type = 'primary',
  icon = <StopOutlined />,
  title = '',
  disabled = false,
  callBack,
}: ButtonRejectQRCodeInterface) => {
  const { t } = useTranslation();
  const { onPostData } = useData();
  const [loading, setLoading] = useState(false);

  const rejectQRCode = useCallback(async () => {
    setLoading(true);

    try {
      const result = await onPostData('request-qr/reject', {
        ids,
      });

      if (result.data.error) {
        Message.warning(t('failure'));
        setLoading(false);
        return;
      }

      await Message.success(
        `${t('success')} ${result.data} ${t('request_qr_rejected')}`
      );

      if (callBack) {
        callBack();
      }
    } catch (error) {
      Message.warning(t('failure'));
      setLoading(false);
      return;
    } finally {
      setLoading(false);
    }
  }, [onPostData, ids, t, callBack]);

  return (
    <Tooltip title={t('reject')}>
      <Button
        type={type}
        icon={icon}
        size="middle"
        loading={loading}
        disabled={disabled}
        onClick={rejectQRCode}
      >
        {title}
      </Button>
    </Tooltip>
  );
};

export default ButtonRejectQRCode;
