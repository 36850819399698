import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, TimePicker, Form, Select } from 'antd';

import CustomFormItem from '../CustomFormItem';
import CustomForm from '../CustomForm';
import moment from 'moment';

interface ModalDatePickerInterface {
  selectedSiteCode: Record<string, any>;
  startDate: string;
  showModal: any;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  setSelectedDate: (value: any) => any;
}

const ModalDatePicker = ({
  selectedSiteCode,
  startDate,
  showModal,
  setShowModal,
  setSelectedDate,
}: ModalDatePickerInterface) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [startTime, setStartTime] = useState(0);

  const isToday = useMemo(
    () =>
      moment(startDate).format('YYMMDD') ===
      moment(Date.now()).format('YYMMDD'),
    [startDate]
  );

  const handleSelectDuration = (value: string) => {
    const [amount, unit]: any = value.split(',');
    const endTime = moment(startTime).add(amount, unit);

    form.setFieldsValue({
      qrcodeDateUntil: endTime,
    });
  };

  const onFinish = (value: any) => {
    const from = moment(value.qrcodeDateFrom).format('HH:mm');
    const to = moment(value.qrcodeDateUntil).format('HH:mm');

    const date = {
      ...selectedSiteCode,
      dateFrom: {
        value: from.replace(':', ''),
        parsed: from,
      },
      dateUntil: {
        value: to.replace(':', ''),
        parsed: to,
      },
    };

    setSelectedDate(date);
    setShowModal(false);
  };

  return (
    <Modal
      title={t('duration')}
      visible={Boolean(showModal)}
      width={400}
      closable={false}
      footer={null}
    >
      <CustomForm
        form={form}
        onFinish={onFinish}
        initialValues={{ qrcodeDeliveryMethod: 'email' }}
        actions={[
          <Form.Item shouldUpdate style={{ margin: 0 }}>
            {() => (
              <Button
                key="submit"
                size="large"
                type="primary"
                htmlType="submit"
                style={{ width: '80%' }}
                disabled={
                  form.getFieldsError().filter(({ errors }) => errors.length)
                    .length > 0
                }
              >
                {t('save')}
              </Button>
            )}
          </Form.Item>,
        ]}
      >
        <CustomFormItem
          name="qrcodeDateFrom"
          label={t('time_from')}
          rules={[{ required: true, message: t('complete_this_field') }]}
          labelCol={{ lg: { span: 8 } }}
        >
          <TimePicker
            style={{ width: '100%' }}
            format="HH:mm"
            onChange={(value: any) => setStartTime(value)}
            disabledHours={() => {
              if (!isToday) return [];

              var hours = [];

              for (var i = 0; i < moment().hour(); i++) {
                hours.push(i);
              }

              return hours;
            }}
            disabledMinutes={(selectedHour) => {
              if (!isToday) return [];

              var minutes = [];

              if (selectedHour === moment().hour()) {
                for (var i = 0; i < moment().minute(); i++) {
                  minutes.push(i);
                }
              }

              return minutes;
            }}
          />
        </CustomFormItem>
        <CustomFormItem
          name="qrcodeDateUntil"
          label={t('time_until')}
          rules={[{ required: true, message: t('complete_this_field') }]}
          labelCol={{ lg: { span: 8 } }}
        >
          <TimePicker
            disabled={!startTime}
            style={{ width: '100%' }}
            format="HH:mm"
            onChange={(value: any) => setStartTime(value)}
            disabledHours={() => {
              if (!isToday) return [];

              var hours = [];
              for (var i = 0; i < moment().hour(); i++) {
                hours.push(i);
              }
              return hours;
            }}
            disabledMinutes={(selectedHour) => {
              if (!isToday) return [];

              var minutes = [];
              if (selectedHour === moment().hour()) {
                for (var i = 0; i < moment().minute(); i++) {
                  minutes.push(i);
                }
              }
              return minutes;
            }}
            renderExtraFooter={() => (
              <CustomFormItem
                name="every"
                labelCol={{ lg: { span: 11 } }}
                style={{ margin: '0px' }}
              >
                <Select
                  onChange={(value) => handleSelectDuration(value)}
                  placeholder={t('duration')}
                >
                  <Select.Option value="30,minutes">
                    {t('add_30_minutes')}
                  </Select.Option>
                  <Select.Option value="1,hours">
                    {t('add_1_hours')}
                  </Select.Option>
                </Select>
              </CustomFormItem>
            )}
          />
        </CustomFormItem>
      </CustomForm>
    </Modal>
  );
};

export default ModalDatePicker;
