import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Card, Input, Button, Tooltip } from 'antd';

import CustomPage from '@/components/CustomPage';
import CustomForm from '@/components/CustomForm';
import CustomFormItem from '@/components/CustomFormItem';
import Message from '@/components/Message';
import { CopyOutlined } from '@ant-design/icons';

import layout from '@/utils/layout';
import { useData } from '@/context';

const PanelSettingsSystem = () => {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const { onFetchData, onPostData } = useData();
  const [loading, setLoading] = useState<boolean>(false);
  const [skeleton, setSkeleton] = useState<boolean>(false);
  const [lineMiniAppHostpath, setLineMiniAppHostpath] = useState<string>('');

  const initial = useCallback(async () => {
    setSkeleton(true);

    const res = await onFetchData('setting/system');

    if (res.error) {
      setLoading(false);
      setSkeleton(false);
      return;
    }

    if (res.data) {
      setLineMiniAppHostpath(res.data.line_mini_app_host_path);
      form.setFieldsValue({
        line_sdk_token: res.data.line_sdk_token,
        line_sdk_secret: res.data.line_sdk_secret,
        line_mini_app_id: res.data.line_mini_app_id,
        line_mini_app_host_path: res.data.line_mini_app_host_path,
        liff_url: res.data.liff_url,
      });
    }

    setSkeleton(false);
    setLoading(false);
  }, [form, onFetchData]);

  const onFinish = async (value: any) => {
    setLoading(true);

    const payload = {
      line_sdk_token: value.line_sdk_token,
      line_sdk_secret: value.line_sdk_secret,
      line_mini_app_id: value.line_mini_app_id,
      liff_url: value.liff_url,
    };

    const res = await onPostData('setting/system', payload);

    if (res.error) {
      Message.warning(t('apply_system_configuration_failed'));
      setLoading(false);
      return;
    }

    await Message.success(t('success'));
    initial();
    setLoading(false);
  };

  useEffect(() => {
    initial();
  }, [initial]);

  return (
    <CustomPage title={t('line_mini_app')} backButton={[<></>]}>
      <CustomForm
        form={form}
        skeleton={skeleton}
        onFinish={onFinish}
        col={layout.form}
        style={{ width: 550 }}
        actions={[
          <Button
            key="submit"
            size="large"
            type="primary"
            htmlType="submit"
            loading={loading}
            style={{ width: 'calc(100% - 48px)' }}
          >
            {t('apply')}
          </Button>,
        ]}
      >
        <Card>
          <CustomFormItem
            name="line_mini_app_host_path"
            skeleton={skeleton}
            label={t('line_mini_app_host_path')}
            labelCol={{ lg: { span: 11 } }}
          >
            <Input.Group compact>
              <Input
                style={{ width: 'calc(100% - 31px)' }}
                defaultValue={lineMiniAppHostpath}
              />
              <Tooltip title={t('copy_line_host_url')}>
                <Button
                  icon={<CopyOutlined />}
                  onClick={() => {
                    navigator.clipboard.writeText(lineMiniAppHostpath);
                  }}
                />
              </Tooltip>
            </Input.Group>
          </CustomFormItem>

          <CustomFormItem
            name="line_sdk_token"
            skeleton={skeleton}
            label={t('line_message_sdk_token')}
            labelCol={{ lg: { span: 11 } }}
            rules={[{ required: true, message: t('complete_this_field') }]}
          >
            <Input.Password placeholder={t('line_message_sdk_token')} />
          </CustomFormItem>

          <CustomFormItem
            name="line_sdk_secret"
            skeleton={skeleton}
            label={t('line_message_sdk_secret')}
            labelCol={{ lg: { span: 11 } }}
            rules={[{ required: true, message: t('complete_this_field') }]}
          >
            <Input.Password placeholder={t('line_message_sdk_secret')} />
          </CustomFormItem>

          <CustomFormItem
            name="line_mini_app_id"
            skeleton={skeleton}
            label={t('line_mini_app_id')}
            labelCol={{ lg: { span: 11 } }}
            rules={[{ required: true, message: t('complete_this_field') }]}
          >
            <Input.Password placeholder={t('line_mini_app_id')} />
          </CustomFormItem>

          <CustomFormItem
            name="liff_url"
            skeleton={skeleton}
            label={t('liff_url')}
            labelCol={{ lg: { span: 11 } }}
            rules={[{ required: true, message: t('complete_this_field') }]}
          >
            <Input placeholder={t('liff_url')} />
          </CustomFormItem>
        </Card>
      </CustomForm>
    </CustomPage>
  );
};

export default PanelSettingsSystem;
