import './index.less';
import { PageHeader, Typography } from 'antd';
import React, { ReactNode } from 'react';

import BackButton from '@/components/BackButton';

interface CustomPageInterface {
  title?: string;
  style?: Record<string, any>;
  children?: ReactNode;
  backButton?: ReactNode[];
}

const CustomPage = (props: CustomPageInterface) => {
  const { title, style, children, backButton } = props;

  const backButtonComponent = (backButton || []).map((item, index) => (
    <React.Fragment key={index}>{item}</React.Fragment>
  ));

  return (
    <PageHeader
      {...props}
      ghost={false}
      style={style}
      className="custom-page"
      title={<Typography.Title>{title}</Typography.Title>}
      extra={
        backButton?.length ? backButtonComponent : [<BackButton key={1} />]
      }
    >
      {children}
    </PageHeader>
  );
};

export default CustomPage;
