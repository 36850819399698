import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, FormInstance } from 'antd';
import { PasswordInput } from 'antd-password-input-strength';

import { useAuth } from '@/context';
import Role from '@/interfaces/role';
import { passwordRegex } from '@/utils/regex';
import CustomFormItem from '@/components/CustomFormItem';

interface InputPasswordInterface {
  userId?: string;
  form?: FormInstance<any>;
  selectedRole?: string;
}

const InputChangePassword = ({
  userId,
  form,
  selectedRole,
}: InputPasswordInterface) => {
  const { t } = useTranslation();
  const [changePassword, setChangePassword] = useState<boolean>(false);
  const { isAdmin, logId, logName } = useAuth();

  const isCurrentLogin = logId === userId;
  const isAdminChangeAdmin =
    isAdmin && selectedRole === Role.Admin && logName !== Role.Admin;

  const disabledUpdatePassword = !isCurrentLogin && isAdminChangeAdmin;

  useEffect(() => {
    if (form) {
      form.setFieldsValue({
        password: '',
      });
    }
  }, [changePassword, form]);

  if (disabledUpdatePassword) {
    return null;
  }

  if (!changePassword) {
    return (
      <Form.Item label={t('password')}>
        <Button
          style={{ width: '100%' }}
          type="default"
          onClick={() => setChangePassword(true)}
        >
          {t('change_password')}
        </Button>
      </Form.Item>
    );
  }

  return (
    <CustomFormItem
      name="password"
      label={t('password')}
      rules={[
        {
          required: true,
          message: t('complete_this_field'),
        },
        {
          required: true,
          pattern: new RegExp(passwordRegex),
          message: t('password_strength'),
        },
      ]}
      labelCol={{ lg: { span: 8 } }}
    >
      <div style={{ display: 'flex' }}>
        <div style={{ width: 'calc(80% - 5px)', marginRight: '5px' }}>
          <PasswordInput placeholder={t('input_password')} />
        </div>

        <Button
          style={{ width: '20%' }}
          type="default"
          onClick={() => setChangePassword(false)}
        >
          {t('cancel')}
        </Button>
      </div>
    </CustomFormItem>
  );
};

export default InputChangePassword;
