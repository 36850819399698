import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import CustomPage from '@/components/CustomPage';

import List from './List';
import CreateUpdate from './CreateUpdate';
import { useGlobal } from '@/context';
import useGetSiteCode from '@/hooks/useGetSiteCode';

const PanelSiteCode = () => {
  const { t } = useTranslation();
  const { data, refetch } = useGetSiteCode();
  const { refetchSiteCode } = useGlobal();

  const refetchData = () => {
    refetch();
    refetchSiteCode();
  };

  return (
    <CustomPage title={t('site_code')}>
      <Tabs type="card" tabPosition="left">
        <Tabs.TabPane tab={t('list_sitecode')} key="1">
          <List data={data} refetch={refetchData} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('create_sitecode')} key="2">
          <CreateUpdate refetch={refetchData} />
        </Tabs.TabPane>
      </Tabs>
    </CustomPage>
  );
};

export default PanelSiteCode;
