import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, Form, Input, Modal } from 'antd';
import { RuleObject } from 'antd/lib/form';
import { StoreValue } from 'antd/lib/form/interface';

import CustomForm from '@/components/CustomForm';
import CustomFormItem from '@/components/CustomFormItem';
import Message from '@/components/Message';
import layout from '@/utils/layout';
import { useData } from '@/context';

interface CreateUpdateSiteCodeInterface {
  id?: string;
  refetch: () => void;
}

const CreateUpdateSiteCode = ({
  id,
  refetch,
}: CreateUpdateSiteCodeInterface) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const { onPostData, onPutData, onFetchData } = useData();
  const [loading, setLoading] = useState<boolean>(false);
  const [skeleton] = useState<boolean>(false);

  const siteCodeValidator = (
    _: RuleObject,
    value: StoreValue,
    callback: (error?: string) => void
  ) => {
    const validInput = Boolean(value.match(/^[a-z]{3}\d{4}$/g));

    if (validInput) {
      callback();
    } else {
      callback(t('invalid_site_code'));
    }
  };

  const onFinish = async (value: any) => {
    setLoading(true);

    const payload = {
      code: value.code,
      label: value.label,
    };

    const cb = id
      ? onPutData(`sitecode/${id}`, payload)
      : onPostData('sitecode', payload);
    const res = await cb;

    if (res.error) {
      Message.warning(
        id ? t('update_sitecode_failed') : t('create_sitecode_failed')
      );
      setLoading(false);
      return;
    }

    await Message.success(t('success'));
    setLoading(false);

    if (!id) {
      form.resetFields();
    }

    refetch();
  };

  useEffect(() => {
    async function initial() {
      try {
        const res = await onFetchData(`sitecode/${id}`);
        if (res.error) {
          Modal.error({
            title: t('error_title'),
            content: t('sitecode_not_found'),
          });
        } else {
          form.setFieldsValue({
            code: res.data.code,
            label: res.data.label,
          });
        }
      } catch (error) {
        Modal.error({
          title: t('error_title'),
          content: t('error_content'),
        });
      }
    }

    id && initial();
  }, [form, id, onFetchData, t]);

  return (
    <CustomForm
      form={form}
      skeleton={skeleton}
      onFinish={onFinish}
      col={layout.form}
      style={{ width: 550, maxHeight: 300 }}
      actions={[
        <Form.Item shouldUpdate style={{ marginRight: 50, marginLeft: 50 }}>
          {() => (
            <Button
              key="submit"
              size="large"
              type="primary"
              htmlType="submit"
              loading={loading}
              style={{ width: 500 }}
              disabled={
                !form.isFieldsTouched(['code']) ||
                form.getFieldsError().filter(({ errors }) => errors.length)
                  .length > 0
              }
            >
              {id ? t('update') : t('create')}
            </Button>
          )}
        </Form.Item>,
      ]}
    >
      <Card>
        <CustomFormItem
          name="code"
          skeleton={skeleton}
          label={t('site_code')}
          rules={[
            { required: true, message: t('complete_this_field') },
            {
              validator: siteCodeValidator,
            },
          ]}
          labelCol={{ lg: { span: 8 } }}
        >
          <Input placeholder={t('site_code')} />
        </CustomFormItem>
        <CustomFormItem
          name="label"
          skeleton={skeleton}
          label={t('site_label')}
          rules={[{ required: true, message: t('complete_this_field') }]}
          labelCol={{ lg: { span: 8 } }}
        >
          <Input placeholder={t('site_label')} />
        </CustomFormItem>
      </Card>
    </CustomForm>
  );
};

export default CreateUpdateSiteCode;
