import { useTranslation } from 'react-i18next';
import { Form, Typography } from 'antd';

import CustomPage from '@/components/CustomPage';
import { useGlobal } from '@/context';

const PanelSettingsAbout = () => {
  const { t } = useTranslation();
  const { appInfo, isMultiClient } = useGlobal();

  const type = isMultiClient ? t('client_type_multi') : t('client_type_single');

  return (
    <CustomPage
      title={t('about')}
      backButton={[<></>]}
      style={{ height: '100%' }}
    >
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        autoComplete="off"
      >
        {isMultiClient && (
          <Form.Item label={t('client_id')} style={{ margin: 0 }}>
            <Typography.Text strong>{appInfo.customerId}</Typography.Text>
          </Form.Item>
        )}
        <Form.Item label={t('client_name')} style={{ margin: 0 }}>
          <Typography.Text strong>{appInfo.customerName}</Typography.Text>
        </Form.Item>
        <Form.Item label={t('client_type')} style={{ margin: 0 }}>
          <Typography.Text strong>{type}</Typography.Text>
        </Form.Item>
      </Form>
    </CustomPage>
  );
};

export default PanelSettingsAbout;
