import api from '@/utils/api';
import { FC, useContext, useCallback, createContext } from 'react';
import axios from 'axios';
import { useAuth } from '@/context';
import config from '@/config/app';
import Message from '@/components/Message';
import { useTranslation } from 'react-i18next';

const useValue = () => {
  const { t } = useTranslation();
  const { hasToken, onLogout } = useAuth();

  const modalNotAuthorize = useCallback(() => {
    if (hasToken) {
      onLogout();
      return;
    }

    Message.warning(t('not_authorize'));
    return;
  }, [hasToken, onLogout, t]);

  const instance = axios.create({
    baseURL: config.app.apiHost,
    ...(hasToken
      ? {
          headers: {
            authorization: `bearer ${hasToken}`,
          },
        }
      : {}),
  });

  const onFetchData = useCallback(
    async (endpoint: string, params?: Record<string, any>) => {
      const result = await api.getAxios(instance, endpoint, params);

      if (result.meta.status === 401) {
        setTimeout(() => {
          modalNotAuthorize();
        }, 500);
      }

      return result;
    },
    [instance, modalNotAuthorize]
  );

  const onPostData = useCallback(
    async (endpoint: string, data?: any) => {
      const result = await api.postAxios(instance, endpoint, data);

      if (result.meta.status === 401) {
        setTimeout(() => {
          modalNotAuthorize();
        }, 500);
      }

      return result;
    },
    [instance, modalNotAuthorize]
  );

  const onPutData = useCallback(
    async (endpoint: string, data?: any) => {
      const result = await api.putAxios(instance, endpoint, data);

      if (result.meta.status === 401) {
        setTimeout(() => {
          modalNotAuthorize();
        }, 500);
      }

      return result;
    },
    [instance, modalNotAuthorize]
  );

  const onDeleteData = useCallback(
    async (endpoint: string) => {
      const result = await api.deleteAxios(instance, endpoint);

      if (result.meta.status === 401) {
        setTimeout(() => {
          modalNotAuthorize();
        }, 500);
      }

      return result;
    },
    [instance, modalNotAuthorize]
  );

  return {
    instance,
    onPutData,
    onPostData,
    onFetchData,
    onDeleteData,
  };
};

const DataContext = createContext({} as ReturnType<typeof useValue>);
const useData = () => useContext(DataContext);

const DataProvider: FC<any> = (props) => {
  return <DataContext.Provider value={useValue()} {...props} />;
};

export { DataProvider, useData };
