import './index.less';
import { ReactNode } from 'react';
import { Layout } from 'antd';
import Header from '@/components/Header';
import background from '@/assets/footer.png';

interface AppLayoutInterface {
  children: ReactNode;
}

const AppLayout = ({ children }: AppLayoutInterface) => {
  return (
    <Layout>
      <img className="img-layout" src={background} alt="backgroundImage" />
      <Header />
      <Layout.Content>{children}</Layout.Content>
    </Layout>
  );
};

export default AppLayout;
