import { Tabs } from 'antd';
import { useAuth, useGlobal } from '@/context';
import { useTranslation } from 'react-i18next';
import CustomPage from '@/components/CustomPage';

import PanelQRBackup from './../PanelQRBackup';
import PanelSettingsSMTP from './../PanelSettingsSMTP';
import PanelSettingsLineMiniApp from '../PanelSettingsLineMiniApp';
import PanelSettingsPrinter from './../PanelSettingsPrinter';
import PanelSettingsEmailTemplate from './../PanelSettingsEmailTemplate';
import PanelSettingsAbout from './../PanelSettingsAbout';
import PanelSettingsFirebase from './../PanelSettingsFirebase';

const PanelSiteCode = () => {
  const { t } = useTranslation();
  const { isSuperAdmin } = useAuth();
  const { hasLineFeature } = useGlobal();

  return (
    <CustomPage title={t('settings')}>
      <Tabs type="card" tabPosition="left">
        <Tabs.TabPane
          tab={t('smtp')}
          key="1"
          style={{ height: 500, overflowX: 'scroll' }}
        >
          <PanelSettingsSMTP />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={t('email_template')}
          key="2"
          style={{ height: 500, overflowX: 'scroll' }}
        >
          <PanelSettingsEmailTemplate />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={t('qr_backup')}
          key="3"
          style={{ height: 500, overflowX: 'scroll' }}
        >
          <PanelQRBackup />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={t('printer')}
          key="4"
          style={{ height: 500, overflowX: 'scroll' }}
        >
          <PanelSettingsPrinter />
        </Tabs.TabPane>
        {isSuperAdmin && hasLineFeature && (
          <Tabs.TabPane
            tab={t('line_mini_app')}
            key="5"
            style={{ height: 500, overflowX: 'scroll' }}
          >
            <PanelSettingsLineMiniApp />
          </Tabs.TabPane>
        )}
        {isSuperAdmin && hasLineFeature && (
          <Tabs.TabPane
            tab={t('firebase')}
            key="6"
            style={{ height: 500, overflowX: 'scroll' }}
          >
            <PanelSettingsFirebase />
          </Tabs.TabPane>
        )}
        <Tabs.TabPane
          tab={t('about')}
          key="7"
          style={{ height: 500, overflowX: 'scroll' }}
        >
          <PanelSettingsAbout />
        </Tabs.TabPane>
      </Tabs>
    </CustomPage>
  );
};

export default PanelSiteCode;
