import { useTranslation } from 'react-i18next';
import { Table, DatePicker, InputNumber, Select } from 'antd';
import { useCallback, useState } from 'react';
import SelectMultipleSiteCode from '@/components/SelectMultipleSiteCode';
import CustomFormItem from '@/components/CustomFormItem';

interface TableImportInterface {
  mappingValue: any;
  listSiteCode: any;
  setListSiteCode: any;
}

const TableImport = ({
  mappingValue,
  listSiteCode,
  setListSiteCode,
}: TableImportInterface) => {
  const [page, setPage] = useState<number>(1);
  const { t } = useTranslation();
  const [dates, setDates] = useState<any>([]);

  const handleChangeDate = useCallback(
    (index: number, value: any) => {
      let list = [...dates];
      list[index] = value;
      setDates(list);
    },
    [dates]
  );

  const handleChangeSiteCode = useCallback(
    (index: number, value: any) => {
      let list = [...listSiteCode];
      list[index] = value;
      setListSiteCode(list);
    },
    [listSiteCode, setListSiteCode]
  );

  const columns: any[] = [
    {
      title: t('number'),
      dataIndex: 'id',
      width: '90px',
      render: (_: any, __: any, index: number) => (page - 1) * 10 + (index + 1),
    },
    {
      title: t('qrcode_num_use'),
      render: (_: undefined, __: undefined, index: number) => {
        return (
          <CustomFormItem
            name={`qrcodeNumUse_${index}`}
            rules={[{ required: true, message: t('complete_this_field') }]}
            style={{ margin: '0px' }}
          >
            <InputNumber
              type="number"
              max={99}
              min={0}
              precision={0}
              placeholder={t('qrcode_num_use')}
            />
          </CustomFormItem>
        );
      },
    },
    {
      title: t('qrcode_start_date'),
      width: '150px',
      render: (_: undefined, __: undefined, index: number) => {
        return (
          <CustomFormItem
            name={`qrcodeStartDate_${index}`}
            rules={[{ required: true, message: t('complete_this_field') }]}
            style={{ margin: '0px' }}
          >
            <DatePicker
              style={{ width: '100%' }}
              format="YYYY-MM-DD"
              onChange={(value) => handleChangeDate(index, value)}
              disabledDate={(current) => {
                return current && current.valueOf() < Date.now();
              }}
              disabledTime={(current: any) => {
                return current && current.valueOf() < Date.now();
              }}
            />
          </CustomFormItem>
        );
      },
    },
    {
      title: t('access'),
      width: '160px',
      render: (_: undefined, __: undefined, index: number) => {
        const list = [...listSiteCode];
        const siteCodes = list[index] || [];

        return (
          <SelectMultipleSiteCode
            startDate={dates[index]}
            listSiteCode={siteCodes}
            setListSiteCode={(value) => handleChangeSiteCode(index, value)}
            children={
              <Select
                disabled={!dates[index]}
                style={{ width: '150px' }}
                value={siteCodes.map((f: any) => f.label)}
                mode="tags"
                placeholder={t('qrcode_site_code')}
                open={false}
              />
            }
          />
        );
      },
    },
    {
      title: t('meeting_group'),
      dataIndex: 'bookingId',
    },
    {
      title: t('company_name'),
      dataIndex: 'companyName',
    },
    {
      title: t('user_name'),
      dataIndex: 'userName',
    },
    {
      title: t('email_address'),
      dataIndex: 'receiver',
    },
  ];

  return (
    <>
      <Table
        key={'schema'}
        columns={columns}
        dataSource={mappingValue}
        rowKey={'id'}
        style={{ width: 1000, height: 500 }}
        scroll={{ x: 1000, y: 365 }}
        pagination={{
          pageSize: 5,
          onChange(current) {
            setPage(current);
          },
        }}
      />
    </>
  );
};

export default TableImport;
