import { useState, useCallback } from 'react';
import { Select, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useData } from '@/context';
import CustomFormItem from '@/components/CustomFormItem';
import useDebounce from '@/hooks/useDebounce';

// Search line profile component
const SearchLineProfile = () => {
  const { t } = useTranslation();
  const { onFetchData } = useData();
  const [searchValue, setSearchValue] = useState<string>('');
  const [fetching, setFetching] = useState<boolean>(false);
  const [data, setData] = useState<any>([]);
  const [options, setOptions] = useState<any>([]);

  // Search user on list line profile
  const searchObj = (arr: Record<string, any>[], searchKey: string) => {
    return arr.filter((obj) =>
      Object.keys(obj).some((key) =>
        obj[key].toLowerCase().includes(searchKey.toLowerCase())
      )
    );
  };

  // Get line profile data using internal cache
  const search = useCallback(async () => {
    if (!searchValue) return;

    setFetching(true);

    try {
      let optionsData = [];

      // If data exist use existing data
      if (data.length) {
        optionsData = data;
      } else {
        // fetch line profiles
        const res = await onFetchData('user/line');

        if (res.error) {
          setData([]);
        } else {
          setData(res.data);
        }

        optionsData = res.data;
      }

      const searchedData = searchObj(optionsData, searchValue);

      setOptions(
        searchedData.map((item) => ({
          label: item.displayName,
          value: item.userId,
        }))
      );
    } catch (error) {
      setOptions([]);
    } finally {
      setFetching(false);
    }
  }, [data, onFetchData, searchValue]);

  // Search debounce
  useDebounce(searchValue, 500, search);

  return (
    <CustomFormItem
      name="qrcodeReceiver"
      rules={[{ required: true, message: t('complete_this_field') }]}
      label={t('qrcode_receiver')}
      labelCol={{ lg: { span: 8 } }}
    >
      <Select
        mode="multiple"
        placeholder={t('qrcode_receiver')}
        labelInValue
        filterOption={false}
        onSearch={(value) => setSearchValue(value)}
        notFoundContent={fetching ? <Spin size="small" /> : null}
        options={options}
      />
    </CustomFormItem>
  );
};

export default SearchLineProfile;
