import './index.less';
import { useEffect, useState } from 'react';
import { FormInstance, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import CustomFormItem from '@/components/CustomFormItem';
import ModalSelectSideCode from './ModalSelectSideCode';

interface SelectMultipleSiteCodeInterface {
  label?: boolean;
  form?: FormInstance<any>;
  startDate: string;
  listSiteCode: Record<string, any>[];
  name?: string;
  setListSiteCode: (value: any) => void;
  children?: any;
}

const SelectMultipleSiteCode = ({
  form,
  label = true,
  startDate,
  listSiteCode,
  name = 'siteCodes',
  setListSiteCode,
  children = null,
}: SelectMultipleSiteCodeInterface) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  const propsItem = label
    ? {
        label: t('qrcode_site_code'),
        labelCol: { lg: { span: 8 } },
      }
    : {};

  useEffect(() => {
    const siteCodes = listSiteCode.map((f: any) => f.label);

    if (form) {
      form.setFieldsValue({ [name]: siteCodes });
    }
  }, [form, listSiteCode, name]);

  return (
    <>
      {/* <CustomForm form={form} style={{ margin: 0, padding: 0 }}> */}
      <div
        className="hide-svg"
        onClick={(e) => {
          if (!startDate) return;
          e.preventDefault();
          setShowModal(true);
        }}
      >
        {Boolean(children) ? (
          children
        ) : (
          <CustomFormItem
            name={name}
            rules={[{ required: true, message: t('complete_this_field') }]}
            {...propsItem}
          >
            <Select
              disabled={!startDate}
              mode="tags"
              placeholder={t('qrcode_site_code')}
              open={false}
            />
          </CustomFormItem>
        )}
      </div>
      {/* </CustomForm> */}

      {showModal && (
        <ModalSelectSideCode
          form={form}
          startDate={startDate}
          showModal={showModal}
          setShowModal={setShowModal}
          listSiteCode={listSiteCode}
          setListSiteCode={setListSiteCode}
        />
      )}
    </>
  );
};

export default SelectMultipleSiteCode;
