import { Redirect } from 'react-router';
import { useAuth } from '@/context';
import { GuardInterface } from '@/interfaces/guard';

export const HasLoginGuard = ({ children }: GuardInterface) => {
  const { hasToken } = useAuth();

  if (hasToken) return <Redirect to={{ pathname: '/panel' }}></Redirect>;

  return <>{children}</>;
};
