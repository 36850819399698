import { useState, useEffect, useCallback } from 'react';
import { Modal } from 'antd';
import { useData } from '@/context';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@/context';

const useGetSiteCodeLabel = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<any[]>([]);
  const { onFetchData } = useData();
  const { hasToken } = useAuth();

  const doGetList = useCallback(async () => {
    try {
      const res = await onFetchData('sitelabels');
      if (res.error) {
        Modal.error({
          title: t('error_title'),
          content: t('error_content'),
        });
        setData([]);
      } else {
        setData(res.data);
      }
    } catch (error) {
      Modal.error({
        title: t('error_title'),
        content: t('error_content'),
      });
      setData([]);
    }
  }, [onFetchData, t]);

  useEffect(() => {
    hasToken && doGetList();
  }, [doGetList, hasToken]);

  return {
    data,
    refetch: doGetList,
  };
};

export default useGetSiteCodeLabel;
