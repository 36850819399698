import '@/styles/global.less';
import i18n from '@/config/i18n';
import AppLayout from '@/layouts';
import { I18nextProvider } from 'react-i18next';
import {
  DataProvider,
  AuthProvider,
  LangProvider,
  GlobalProvider,
} from '@/context';
import { ConfigProvider } from 'antd';
import { BrowserRouter } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import AppRoutes from '@/routes';
import { useLang } from '@/context';

import en_US from 'antd/lib/locale/en_US';
import ja_JP from 'antd/lib/locale/ja_JP';

const EN_LANG = 'en';

const Provider = () => {
  const { lang } = useLang();
  const language = useMemo(() => (lang === EN_LANG ? en_US : ja_JP), [lang]);

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  return (
    <I18nextProvider i18n={i18n}>
      <ConfigProvider locale={language}>
        <BrowserRouter>
          <AppLayout>
            <AppRoutes />
          </AppLayout>
        </BrowserRouter>
      </ConfigProvider>
    </I18nextProvider>
  );
};

export default function App() {
  return (
    <AuthProvider>
      <DataProvider>
        <GlobalProvider>
          <LangProvider>
            <Provider />
          </LangProvider>
        </GlobalProvider>
      </DataProvider>
    </AuthProvider>
  );
}
