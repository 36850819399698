import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { Link } from 'react-router-dom';

import CustomPage from '@/components/CustomPage';
import CreateUpdate from '@/pages/PanelUser/CreateUpdate';
import { useParams } from 'react-router-dom';
import useGetUsers from '@/hooks/useGetUsers';

const PanelUserDetail = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const { refetch } = useGetUsers();

  const backButton = [
    <Button key="back" type="default">
      <Link
        to={{
          pathname: '/panel/user',
          state: { prevPath: window.location.pathname },
        }}
      >
        {t('back_to_user_panel')}
      </Link>
    </Button>,
  ];

  return (
    <CustomPage title={t('detail_user')} backButton={backButton}>
      <CreateUpdate id={id} refetch={refetch} />
    </CustomPage>
  );
};

export default PanelUserDetail;
