export const safeJSONparse = (value: string, defaultValue?: any) => {
  try {
    return JSON.parse(value);
  } catch (e) {
    return (
      defaultValue || { error: `JSON Schema error please updated it (${e})` }
    );
  }
};

export const formatField = (value: string) => {
  if (!value) return '';

  const string = value.replace(/undefined/gi, '');
  const field = string
    .split('.')
    .filter((item: string) => item)
    .join(' ');

  return field;
};

export const csvJSON = (csv: string) => {
  let lines = csv.split('\n');
  let result = [];
  let headers = lines[0]
    .split(',')
    .map((m: string) => m.replace(/(\r\n|\n|\r)/gm, ''));

  for (var i = 1; i < lines.length; i++) {
    var obj: any = {};
    var currentline = lines[i].split(',');

    for (var j = 0; j < headers.length; j++) {
      obj[headers[j]] = currentline[j];
    }

    result.push(obj);
  }

  return { headers, data: result };
};
