import { useState, useEffect, useCallback } from 'react';
import { Modal } from 'antd';
import { useData } from '@/context';
import { useTranslation } from 'react-i18next';

const useGetUsers = () => {
  const { t } = useTranslation();
  const [, setSkeleton] = useState<boolean>(false);
  const [data, setData] = useState<any[]>([]);
  const { onFetchData } = useData();

  const doGetList = useCallback(async () => {
    setSkeleton(true);

    try {
      const res = await onFetchData('users');
      if (res.error) {
        Modal.error({
          title: t('error_title'),
          content: t('error_content'),
        });
        setData([]);
      } else {
        setData(res.data);
      }
    } catch (error) {
      Modal.error({
        title: t('error_title'),
        content: t('error_content'),
      });
      setData([]);
    } finally {
      setSkeleton(false);
    }
  }, [onFetchData, t]);

  useEffect(() => {
    doGetList();
  }, [doGetList]);

  return {
    data,
    refetch: doGetList,
  };
};

export default useGetUsers;
