import { FC, useState, useContext, useCallback, createContext } from 'react';
import { useCookies } from 'react-cookie';
import Cookie from '@/interfaces/cookie';
import AppConfig from '@/config/app';

const useValue = () => {
  const [cookies, setCookie] = useCookies([Cookie.Lang]);
  const [lang, setLang] = useState(
    cookies[Cookie.Lang] || AppConfig.app.defaultLang
  );

  const changeLang = useCallback(
    async (lang: string) => {
      setCookie(Cookie.Lang, lang);
      setLang(lang);
    },
    [setCookie]
  );

  return {
    lang,
    changeLang,
  };
};

const LangContext = createContext({} as ReturnType<typeof useValue>);
const useLang = () => useContext(LangContext);
const LangProvider: FC<any> = (props) => {
  return <LangContext.Provider value={useValue()} {...props} />;
};

export { LangProvider, useLang };
