import { Redirect } from 'react-router';
import { GuardInterface } from '@/interfaces/guard';
import { useGlobal } from '@/context';

export const ShouldLicensedGuard = ({ children }: GuardInterface) => {
  const { license, loadingLicense } = useGlobal();

  if (loadingLicense) return null;

  if (!loadingLicense && license) return <>{children}</>;

  return <Redirect to={{ pathname: '/init-license' }}></Redirect>;
};
