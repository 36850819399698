// Frontend is served from the server on production.
// So that, the api host should be based on location that being accessed.
const apiHost = window.location.origin;

const config = {
  app: {
    apiHost: `${apiHost}/api/`,
    defaultLang: 'en',
    dateFormat: 'MMM DD, YYYY',
    dateTimeFormat: 'MMM DD, YYYY hh:mm',
    secretKey: process.env.REACT_APP_SECRET_KEY,
  },
  encryption: {
    algorithm: 'aes-256-cbc',
    key: 'b84801925548724b357c288d29c366b0b274e96e3c9898501b69a51504a5aef2',
    iv: '2ebb8b6cfbb921dc9c41b41d34d5a22c',
  },
};

export default config;
