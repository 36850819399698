import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from 'antd';

const BackButton = () => {
  const { t } = useTranslation();

  return (
    <Button key="back" type="default">
      <Link
        to={{
          pathname: '/panel',
          state: { prevPath: window.location.pathname },
        }}
      >
        {t('back_to_admin_panel')}
      </Link>
    </Button>
  );
};

export default BackButton;
