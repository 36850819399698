import Swal from 'sweetalert2';

const timer = 3000;

const success = (text?: string) => {
  return Swal.fire({
    timer,
    text,
    icon: 'success',
    showCancelButton: false,
    showConfirmButton: false,
  });
};

const warning = (text?: string) => {
  return Swal.fire({
    timer,
    text,
    icon: 'warning',
    showCancelButton: false,
    showConfirmButton: false,
  });
};

const error = (text?: string) => {
  return Swal.fire({
    timer,
    text,
    icon: 'error',
    title: 'Error!',
    showCancelButton: false,
    showConfirmButton: false,
  });
};

const confirm = (
  text?: string,
  cancelButtonText: string = 'Cancel',
  confirmButtonText: string = 'OK'
) => {
  return Swal.fire({
    text,
    icon: 'question',
    showCancelButton: true,
    showConfirmButton: true,
    cancelButtonText: cancelButtonText,
    confirmButtonText: confirmButtonText,
  });
};

const message = { success, warning, error, confirm };

export default message;
