import { ReactNode } from 'react';
import { Form } from 'antd';
import { ColProps } from 'antd/lib/grid/col';

import CustomCard from '@/components/CustomCard';
import { FormLayout } from 'antd/lib/form/Form';

interface LayoutInterface {
  labelCol?: ColProps;
  wrapperCol?: ColProps;
}

interface CustomFormInterface {
  form?: any;
  name?: string;
  style?: Record<string, any>;
  col?: LayoutInterface;
  layout?: FormLayout;
  children?: ReactNode;
  onFinish?: (value: any) => void;
  skeleton?: boolean;
  initialValues?: Record<string, string>;
  actions?: ReactNode[];
  bordered?: boolean;
}

const CustomForm = ({
  form,
  name,
  style,
  col,
  layout,
  children,
  onFinish,
  skeleton,
  initialValues,
  actions = [],
  bordered = false,
}: CustomFormInterface) => {
  const props = !skeleton ? { form, name, onFinish, initialValues } : undefined;

  return (
    <Form
      {...props}
      {...col}
      layout={layout}
      labelAlign="left"
      initialValues={{ ...initialValues }}
    >
      <CustomCard
        style={style}
        actions={actions}
        bordered={bordered}
        skeleton={skeleton}
      >
        {children}
      </CustomCard>
    </Form>
  );
};

export default CustomForm;
