import { useEffect, useState } from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useData, useAuth } from '@/context';
import CustomFormItem from '@/components/CustomFormItem';

interface SelectRoleInterface {
  userId?: string;
}

const SelectRole = ({ userId }: SelectRoleInterface) => {
  const { t } = useTranslation();
  const { onFetchData } = useData();
  const [roles, setRoles] = useState([]);
  const { logId } = useAuth();

  const isCurrentLogin = logId === userId;

  useEffect(() => {
    async function initial() {
      try {
        const res = await onFetchData('roles');
        if (res.error) {
          setRoles([]);
        } else {
          setRoles(res.data);
        }
      } catch (error) {
        setRoles([]);
      }
    }

    initial();
  }, [onFetchData, t]);

  return (
    <CustomFormItem
      name="role"
      label={t('user_role')}
      rules={[{ required: true, message: t('complete_this_field') }]}
      labelCol={{ lg: { span: 8 } }}
    >
      <Select
        style={{ width: '100%' }}
        placeholder={t('select_role')}
        disabled={isCurrentLogin}
      >
        {roles.map((item: any, index: number) => (
          <Select.Option key={index} value={item}>
            {t(item)}
          </Select.Option>
        ))}
      </Select>
    </CustomFormItem>
  );
};

export default SelectRole;
