import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Space, Spin, Typography } from 'antd';

import { useData } from '@/context';
import downloadCSV from '@/utils/download-csv';

interface ModalExportInterface {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  fileName: string;
  page?: number;
}

const ModalExport = ({
  showModal,
  setShowModal,
  fileName,
  page,
}: ModalExportInterface) => {
  const { Title } = Typography;
  const { t } = useTranslation();
  const { onFetchData } = useData();
  const [loading, setLoading] = useState<boolean>(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleExport = async (type?: string) => {
    setLoading(true);

    const params =
      type === 'all'
        ? {}
        : {
            limit: 10,
            offset: page,
          };

    const response = await onFetchData('qr-codes/export/csv', params);

    if (response.error) {
      Modal.error({
        title: t('error_title'),
        content: t('error_content'),
        onOk: () => setLoading(false),
      });
      return;
    }

    downloadCSV([response.data], fileName);
    setLoading(false);
    handleCloseModal();
  };

  return (
    <Modal
      title={t('export_csv')}
      visible={showModal}
      onCancel={handleCloseModal}
      footer={[
        <Button key={1} onClick={handleCloseModal}>
          {t('cancel')}
        </Button>,
      ]}
    >
      {loading ? (
        <div style={{ textAlign: 'center' }}>
          <Spin />
          <Title level={5}>{t('generating_csv')}</Title>
        </div>
      ) : (
        <Space size="middle">
          <Button onClick={() => handleExport('current')}>
            {t('export_current_view')}
          </Button>
          <Button onClick={() => handleExport('all')}>{t('export_all')}</Button>
        </Space>
      )}
    </Modal>
  );
};

export default ModalExport;
