import {
  FC,
  useContext,
  createContext,
  useMemo,
  useState,
  useCallback,
} from 'react';
import useGetSiteCodeLabel from '@/hooks/useGetSiteCodeLabel';
import useGetLicense from '@/hooks/useGetLicense';
import { useData } from './data';

const useValue = () => {
  const [loading, setLoading] = useState(false);
  const { data: siteCodes, refetch: refetchSiteCode } = useGetSiteCodeLabel();
  const { data: license, loading: loadingLicense, appInfo } = useGetLicense();
  const [requestedQr, setDataSource] = useState<any>([]);
  const { onFetchData } = useData();

  const fetchRequestedQr = useCallback(async () => {
    setLoading(true);
    try {
      const res = await onFetchData('request-qr');

      if (res.error) {
        setDataSource([]);
      } else {
        const data = res.data.map((m: any, key: number) => ({ ...m, key }));

        setDataSource(data);
      }
    } catch (error) {
      setDataSource([]);
    }

    setLoading(false);
  }, [onFetchData]);

  const isMultiClient = useMemo(
    () => appInfo.customerType === 'multi' && appInfo.customerId,
    [appInfo.customerId, appInfo.customerType]
  );

  const hasLineFeature = useMemo(
    () => Boolean(appInfo.lineFeature),
    [appInfo.lineFeature]
  );

  return {
    siteCodes,
    refetchSiteCode,
    license,
    loadingLicense,
    hasLineFeature,
    appInfo,
    isMultiClient,
    requestedQr,
    fetchRequestedQr,
    requestedQrLoading: loading,
  };
};

const GlobalContext = createContext({} as ReturnType<typeof useValue>);
const useGlobal = () => useContext(GlobalContext);
const GlobalProvider: FC<any> = (props) => {
  return <GlobalContext.Provider value={useValue()} {...props} />;
};

export { GlobalProvider, useGlobal };
