import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, FormInstance, Modal, Table } from 'antd';

import { useGlobal } from '@/context';
import ModalDatePicker from './ModalSelectDate';
import Message from '@/components/Message';

interface ModalSelectSideCodeInterface {
  form?: FormInstance<any>;
  startDate: string;
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  listSiteCode: Record<string, any>[];
  setListSiteCode: (value: any) => void;
}

const MAX_SITE_CODE = 14;

const ModalSelectSideCode = ({
  form,
  startDate,
  showModal,
  setShowModal,
  listSiteCode,
  setListSiteCode,
}: ModalSelectSideCodeInterface) => {
  const [page, setPage] = useState<number>(1);
  const { t } = useTranslation();
  const { siteCodes } = useGlobal();
  const [showModalDatePicker, setShowModalDatePicker] =
    useState<boolean>(false);
  const [selectedSiteCode, setSelectedSiteCode] = useState({});

  const columns: any[] = [
    {
      title: t('number'),
      dataIndex: 'id',
      render: (_: any, __: any, index: number) => (page - 1) * 10 + (index + 1),
    },
    {
      title: t('site_code'),
      dataIndex: 'label',
    },
    {
      title: t('duration'),
      dataIndex: 'date',
      render: (_: any, record: any) => {
        const selectedData = listSiteCode.find((f: any) => f.id === record.id);
        return selectedData
          ? `${selectedData.dateFrom.parsed} - ${selectedData.dateUntil.parsed}`
          : t('not_set');
      },
    },
  ];

  const rowSelection = {
    selectedRowKeys: listSiteCode.map((m) => m.id),
    onSelect: (record: any, status: boolean) => {
      if (listSiteCode.length === MAX_SITE_CODE) {
        Message.warning(t('max_14_site_code'));
        return;
      }

      if (status) {
        setSelectedSiteCode(record);
        setShowModalDatePicker(true);
      } else {
        const arr = listSiteCode.filter((item: any) => item.id !== record.id);
        setListSiteCode(arr);
      }
    },
    getCheckboxProps: (record: any) => ({
      name: record.name,
    }),
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const pushSiteCode = (value: any) => {
    listSiteCode.push(value);
    setListSiteCode(listSiteCode);

    if (form) {
      form.setFieldsValue({
        siteCodes: listSiteCode.map((f: any) => f.label),
      });
    }
  };

  return (
    <Modal
      title={t('list_site_code')}
      visible={showModal}
      width={600}
      onCancel={handleCloseModal}
      footer={[
        <Button key={2} type="primary" onClick={handleCloseModal}>
          {t('ok')}
        </Button>,
      ]}
    >
      <Table
        rowSelection={{
          type: 'checkbox',
          hideSelectAll: true,
          ...rowSelection,
        }}
        key={'printer'}
        columns={columns}
        dataSource={siteCodes}
        rowKey={'id'}
        pagination={{
          onChange(current) {
            setPage(current);
          },
        }}
      />

      {Boolean(showModalDatePicker) && (
        <ModalDatePicker
          startDate={startDate}
          selectedSiteCode={selectedSiteCode}
          showModal={showModalDatePicker}
          setShowModal={setShowModalDatePicker}
          setSelectedDate={pushSiteCode}
        />
      )}
    </Modal>
  );
};

export default ModalSelectSideCode;
