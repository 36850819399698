import { RoutesInterface } from '@/interfaces/routes';

const dashboardGuards = (
  isAdmin: boolean,
  isSub: boolean,
  isUser: boolean,
  hasLineFeature: boolean,
  item: RoutesInterface
) => {
  let isAllowed = false;

  if (isAdmin) {
    isAllowed = item?.isAdmin || true;
  }

  if (isSub) {
    isAllowed = item?.isSub || false;
  }

  if (isUser) {
    isAllowed = item?.isUser || false;
  }

  if (item?.isLine) {
    isAllowed = hasLineFeature;
  }

  return isAllowed;
};

export default dashboardGuards;
