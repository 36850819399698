import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import ModalExport from './Modal';

interface ButtonExportCsvInterface {
  fileName: string;
  page?: number;
}

const ButtonExportCsv = ({ page, fileName }: ButtonExportCsvInterface) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Button type="default" onClick={() => setShowModal(true)}>
        {t('export_csv')}
      </Button>
      {showModal && (
        <ModalExport
          showModal={showModal}
          setShowModal={setShowModal}
          fileName={fileName}
          page={page}
        />
      )}
    </>
  );
};

export default ButtonExportCsv;
