import './index.less';
import { Col, Row, Button } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAuth, useGlobal } from '@/context';
import AppRoutes from '@/routes/routes';
import dashboardGuards from '@/utils/dashboard-guards';

const Dashboard = () => {
  const { t } = useTranslation();
  const { isAdmin, isSub, isUser } = useAuth();
  const { hasLineFeature } = useGlobal();

  return (
    <div className="full-width">
      <Row justify="center" gutter={[0, 30]}>
        {AppRoutes.filter((item) => item.icon)
          .filter((item) =>
            dashboardGuards(isAdmin, isSub, isUser, hasLineFeature, item)
          )
          .map(({ icon, path, title }, index) => (
            <Col key={index} span={5}>
              <Button type="primary" className="menu-btn">
                <Link
                  to={{
                    pathname: path,
                    state: { prevPath: window.location.pathname },
                  }}
                >
                  {icon}
                  <div className="menu-label">{t(title || '')}</div>
                </Link>
              </Button>
            </Col>
          ))}
      </Row>
    </div>
  );
};

export default Dashboard;
