import { useTranslation } from 'react-i18next';
import { FormInstance, Upload, UploadProps } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

import message from '@/components/Message';
import CustomFormItem from '@/components/CustomFormItem';
import { useMemo } from 'react';
import { csvJSON } from '@/utils/string';

interface DndFileInterface {
  form: FormInstance<any>;
  defaultFileList: any;
  setDefaultFileList: (value: any) => void;
  setCSV: (value: any) => void;
}

const DndFile = ({
  defaultFileList,
  setDefaultFileList,
  setCSV,
  form,
}: DndFileInterface) => {
  const { t } = useTranslation();
  const { Dragger } = Upload;

  const props: UploadProps = useMemo(
    () => ({
      name: 'file',
      accept: '.csv',
      multiple: false,
      style: { padding: '20px' },
      defaultFileList,
      onRemove: () => {
        setCSV(null);
        setDefaultFileList([]);
        form.setFieldsValue({
          file: null,
        });
      },
      beforeUpload: (file) => {
        const reader = new FileReader();

        reader.onload = (e: any) => {
          const csvString = e.target.result;
          const data = csvJSON(csvString);

          setCSV(data);
          setDefaultFileList([file]);
        };
        reader.readAsText(file);
      },
      onChange: (info) => {
        const { status } = info.file;
        if (status === 'done') {
          message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
          message.error(`${info.file.name} file upload failed.`);
        }
      },
      customRequest: (options: any) => {
        const { onSuccess } = options;
        onSuccess(null, undefined);
      },
    }),
    [defaultFileList, form, setCSV, setDefaultFileList]
  );

  return (
    <CustomFormItem
      name="file"
      rules={[{ required: true, message: t('complete_this_field') }]}
    >
      <Dragger {...props}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">{t('import_text')}</p>
      </Dragger>
    </CustomFormItem>
  );
};

export default DndFile;
