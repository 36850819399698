import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, Form, Input, Modal } from 'antd';
import { PasswordInput } from 'antd-password-input-strength';

import layout from '@/utils/layout';
import Role from '@/interfaces/role';
import { RuleObject } from 'antd/lib/form';
import Message from '@/components/Message';
import { useData, useAuth } from '@/context';
import CustomForm from '@/components/CustomForm';
import SelectRole from '@/components/SelectRole';
import { StoreValue } from 'antd/lib/form/interface';
import CustomFormItem from '@/components/CustomFormItem';
import { emailRegex, passwordRegex } from '@/utils/regex';
import InputChangePassword from '@/components/InputChangePassword';

interface PanelUserCreateUpdateInterface {
  id?: string;
  refetch: () => void;
}

const PanelUserCreateUpdate = ({
  id,
  refetch,
}: PanelUserCreateUpdateInterface) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const { onPostData, onPutData, onFetchData } = useData();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRole, setSelectedRole] = useState<string>('');
  const [skeleton] = useState<boolean>(false);
  const [isSuperAdmin, setSupreAdmin] = useState<boolean>(false);
  const { onLogout, logId } = useAuth();

  const emailValidator = (
    _: RuleObject,
    value: StoreValue,
    callback: (error?: string) => void
  ) => {
    const validInput = Boolean(value.match(emailRegex));

    if (validInput) {
      callback();
    } else {
      callback(t('invalid_email'));
    }
  };

  const isCurrentLogin = id ? logId === id : false;

  const onFinish = async (value: any) => {
    setLoading(true);

    const payload = value;

    const cb = id
      ? onPutData(`user/${id}`, payload)
      : onPostData('user', payload);
    const res = await cb;

    if (res.error) {
      Message.warning(id ? t('update_user_failed') : t('create_user_failed'));
      setLoading(false);
      return;
    }

    if (!isSuperAdmin && isCurrentLogin) {
      await Message.success(t('change_password_relogin'));
      setTimeout(() => {
        onLogout();
      }, 3000);
      setLoading(false);

      return;
    }

    await Message.success(t('success'));
    setLoading(false);

    if (!id) {
      form.resetFields();
      refetch();
    }
  };

  useEffect(() => {
    async function initial() {
      try {
        const res = await onFetchData(`user/${id}`);
        if (res.error) {
          Modal.error({
            title: t('error_title'),
            content: t('user_not_found'),
          });
        } else {
          form.setFieldsValue({
            username: res.data.username,
            password: res.data.password,
            role: res.data.role,
            email: res.data.email,
          });

          setSupreAdmin(
            res.data.role === Role.Admin && res.data.username === Role.Admin
          );
          setSelectedRole(res.data.role);
        }
      } catch (error) {
        Modal.error({
          title: t('error_title'),
          content: t('error_content'),
        });
      }
    }

    id && initial();
  }, [form, id, onFetchData, t]);

  return (
    <CustomForm
      form={form}
      skeleton={skeleton}
      onFinish={onFinish}
      col={layout.form}
      style={{ width: 550, maxHeight: 300 }}
      actions={[
        <Form.Item shouldUpdate style={{ marginRight: 50, marginLeft: 50 }}>
          {() => (
            <Button
              key="submit"
              size="large"
              type="primary"
              htmlType="submit"
              loading={loading}
              style={{ width: 500 }}
              disabled={
                (!isSuperAdmin && !form.isFieldsTouched(['username'])) ||
                (isSuperAdmin && !form.isFieldsTouched(['email'])) ||
                form.getFieldsError().filter(({ errors }) => errors.length)
                  .length > 0
              }
            >
              {id ? t('update') : t('create')}
            </Button>
          )}
        </Form.Item>,
      ]}
    >
      <Card>
        {!isSuperAdmin && (
          <>
            <CustomFormItem
              name="username"
              skeleton={skeleton}
              label={t('user_name')}
              rules={[{ required: true, message: t('complete_this_field') }]}
              labelCol={{ lg: { span: 8 } }}
            >
              <Input placeholder={t('input_user_name')} />
            </CustomFormItem>

            {id ? (
              <InputChangePassword
                form={form}
                selectedRole={selectedRole}
                userId={id}
              />
            ) : (
              <CustomFormItem
                name="password"
                skeleton={skeleton}
                label={t('password')}
                rules={[
                  {
                    required: true,
                    message: t('complete_this_field'),
                  },
                  {
                    required: true,
                    pattern: new RegExp(passwordRegex),
                    message: t('password_strength'),
                  },
                ]}
                labelCol={{ lg: { span: 8 } }}
              >
                <PasswordInput placeholder={t('input_password')} />
              </CustomFormItem>
            )}

            <SelectRole userId={id} />
          </>
        )}

        <CustomFormItem
          name="email"
          skeleton={skeleton}
          label={t('email')}
          rules={[
            { required: true, message: t('complete_this_field') },
            {
              validator: emailValidator,
            },
          ]}
          labelCol={{ lg: { span: 8 } }}
        >
          <Input placeholder={t('email')} />
        </CustomFormItem>
      </Card>
    </CustomForm>
  );
};

export default PanelUserCreateUpdate;
