import { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Space, Table, Tooltip } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';

import { useData } from '@/context';

interface ModalSearchPrinterInterface {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  setPrinterName: Dispatch<SetStateAction<string>>;
}

const ModalSearchPrinter = ({
  showModal,
  setShowModal,
  setPrinterName,
}: ModalSearchPrinterInterface) => {
  const { t } = useTranslation();
  const { onFetchData } = useData();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState<boolean>(true);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const columns: any[] = [
    {
      width: 20,
      title: t('number'),
      dataIndex: 'id',
      render: (_: any, __: any, index: number) => index + 1,
    },
    {
      width: 50,
      title: t('printer_name'),
      dataIndex: 'name',
    },
    {
      width: 20,
      title: t('printer_status'),
      dataIndex: 'status',
    },
    {
      width: 20,
      title: t('action'),
      dataIndex: 'action',
      render: (_: string, record: Record<string, any>) => {
        const printerName =
          record.options?.['printer-uri-supported'] ||
          record.options?.['device-uri'] ||
          record.name ||
          '';

        return (
          <Space>
            <Tooltip title={t('add_printer')}>
              <Button
                type="primary"
                icon={<PlusCircleOutlined />}
                size="middle"
                onClick={() => {
                  setPrinterName(printerName);
                  handleCloseModal();
                }}
              />
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    async function initial() {
      const res = await onFetchData('printer/search');

      if (res.error) {
        setLoading(false);
        return;
      }

      if (res.data) {
        setData(res.data);
      }

      setLoading(false);
    }

    initial();
  }, [onFetchData, t]);

  return (
    <Modal
      title={t('list_printer')}
      visible={showModal}
      width={600}
      onCancel={handleCloseModal}
      footer={[
        <Button key={1} onClick={handleCloseModal}>
          {t('cancel')}
        </Button>,
      ]}
    >
      <Table
        key={'printer'}
        columns={columns}
        dataSource={data}
        loading={loading}
        rowKey={'id'}
        style={{ width: 600 }}
        scroll={{ x: 600, y: 300 }}
      />
    </Modal>
  );
};

export default ModalSearchPrinter;
