import { useTranslation } from 'react-i18next';
import { Collapse } from 'antd';

import CustomPage from '@/components/CustomPage';

import ActivateLicense from './Activate';
import RequestLicense from './Request';

const PanelLicense = () => {
  const { t } = useTranslation();

  const collapse = [
    {
      title: t('request_offline_license'),
      component: <RequestLicense />,
    },
    {
      title: t('activation'),
      component: <ActivateLicense />,
    },
  ];

  return (
    <CustomPage title={t('license')} backButton={[<></>]}>
      <Collapse defaultActiveKey={[0, 1]}>
        {collapse.map((item, index) => (
          <Collapse.Panel key={index} showArrow={false} header={item.title}>
            {item.component}
          </Collapse.Panel>
        ))}
      </Collapse>
    </CustomPage>
  );
};

export default PanelLicense;
