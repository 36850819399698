import { useTranslation } from 'react-i18next';
import CustomFormItem from '@/components/CustomFormItem';
import { Table, Select } from 'antd';
import { useMemo } from 'react';

interface DataMappingInterface {
  csv: any;
}

const DataMapping = ({ csv }: DataMappingInterface) => {
  const { t } = useTranslation();

  const handleSelectField = () => {};

  const columnsData = [
    { name: 'meeting_group', field: 'bookingId' },
    { name: 'company_name', field: 'companyName' },
    { name: 'user_name', field: 'userName' },
    { name: 'email_address', field: 'receiver' },
  ];

  const columns: any[] = useMemo(
    () => [
      {
        title: t('number'),
        dataIndex: 'id',
        width: 50,
        render: (_: undefined, __: undefined, index: number) => index + 1,
      },
      {
        title: t('to_field'),
        dataIndex: 'name',
        width: 150,
        render: (value: string) => t(value),
      },
      {
        title: t('from_field'),
        dataIndex: 'from_field',
        render: (_: undefined, record: Record<string, any>, index: number) => {
          return (
            <CustomFormItem
              key={index}
              noStyle={true}
              name={record.field}
              rules={[{ required: true, message: t('complete_this_field') }]}
            >
              <Select
                style={{ width: '100%' }}
                mode="tags"
                placeholder={`${t('select')} ${t(record.name)} ${t('field')}`}
                onChange={handleSelectField}
              >
                {(csv.headers || []).map((item: string, index: number) => (
                  <Select.Option key={`${item}-${index}`} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </CustomFormItem>
          );
        },
      },
    ],
    [csv.headers, t]
  );

  return (
    <Table
      key={'schema'}
      columns={columns}
      dataSource={columnsData}
      rowKey={'id'}
      pagination={false}
    />
  );
};

export default DataMapping;
