import { Table, Space, Row, Col, Button, Tooltip } from 'antd';
import React, { useEffect, useState, useMemo } from 'react';
import { ReloadOutlined } from '@ant-design/icons';

import { useGlobal } from '@/context';
import { useTranslation } from 'react-i18next';
import CustomPage from '@/components/CustomPage';
import CustomCard from '@/components/CustomCard';
import BackButton from '@/components/BackButton';
import ApproveQRCode from '@/components/ApproveQRCode';
import RejectQRCode from '@/components/RejectQRCode';
import moment from 'moment';
import EditableRow from './EditableRow';
import EditableCell from './EditableCell';

const App: React.FC = () => {
  const { t } = useTranslation();
  const [dataSource, setDataSource] = useState<any>([]);
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const { siteCodes, fetchRequestedQr, requestedQr, requestedQrLoading } =
    useGlobal();

  const formatDate = (date: string): string => {
    if (!date) return '';

    return moment(date).format('DD MMMM yyyy');
  };

  const defaultColumns: any[] = [
    {
      title: t('name'),
      dataIndex: 'name',
      width: 90,
    },
    {
      title: t('company'),
      dataIndex: 'company',
      width: 100,
    },
    {
      title: t('purpose'),
      dataIndex: 'purpose',
      width: 150,
      render: (value: string, record: any) => {
        return `${value}, ${record.appointment}`;
      },
    },
    {
      title: t('date'),
      dataIndex: 'startDate',
      render: (date: string, record: any) => {
        return `${formatDate(date)} ${record.startTime}-${record.endTime}`;
      },
      width: 150,
    },
    {
      title: t('qrcode_num_use'),
      dataIndex: 'numUse',
      width: 180,
      editable: true,
      render: (value: any) => {
        if (value !== undefined) return value;

        return <Button>{t('input_num_use')}</Button>;
      },
    },
    {
      title: t('site_code'),
      dataIndex: 'siteCode',
      width: 170,
      editable: true,
      render: (value: any) => {
        const selected = siteCodes.find((f) => f.id === value);

        if (selected) return selected.label;

        return <Button>{t('select_sitecode')}</Button>;
      },
    },
    {
      title: t('action'),
      dataIndex: 'action',
      width: 100,
      render: (_: string, record: Record<string, string>) => {
        const disabled = Boolean(selectedRow.length || !record.siteCode);
        const params = [
          {
            id: record.id,
            siteCode: record.siteCode,
            numUse: Number(record.numUse),
          },
        ];

        return (
          <Space>
            <ApproveQRCode
              ids={params}
              disabled={disabled}
              callBack={fetchRequestedQr}
            />
            <RejectQRCode
              disabled={Boolean(selectedRow.length)}
              ids={params}
              callBack={fetchRequestedQr}
            />
          </Space>
        );
      },
    },
  ];

  const handleSave = (row: any) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const ids = useMemo(
    () =>
      selectedRow.map((item: any) => ({
        id: item.id,
        siteCode: item.siteCode,
        numUse: Number(item.numUse),
      })),
    [selectedRow]
  );

  const rowSelection = {
    onChange: (_: any, selectedRows: any) => {
      setSelectedRow(selectedRows);
    },
    getCheckboxProps: (record: any) => {
      return {
        disabled: !record.siteCode,
        name: record.name,
      };
    },
  };

  useEffect(() => {
    fetchRequestedQr();
  }, [fetchRequestedQr]);

  useEffect(() => {
    if (requestedQr) {
      setDataSource(requestedQr);
    }
  }, [requestedQr]);

  const TableComponent = (
    <>
      <CustomCard style={{ maxWidth: 1000 }}>
        <Row style={{ marginBottom: 20 }}>
          <Col span={24}>
            <Space align="end" style={{ justifyContent: 'end', width: '100%' }}>
              <Tooltip title={t('approve')}>
                <Button
                  type="default"
                  icon={<ReloadOutlined />}
                  size="middle"
                  onClick={fetchRequestedQr}
                >
                  {t('refresh')}
                </Button>
              </Tooltip>
              {Boolean(selectedRow.length) && (
                <>
                  <ApproveQRCode
                    ids={ids}
                    icon={null}
                    type="default"
                    title={t('approve')}
                    callBack={fetchRequestedQr}
                  />
                  <RejectQRCode
                    ids={ids}
                    icon={null}
                    type="default"
                    title={t('reject')}
                    callBack={fetchRequestedQr}
                  />
                </>
              )}
            </Space>
          </Col>
        </Row>
        <Table
          loading={requestedQrLoading}
          components={components}
          rowClassName={() => 'editable-row'}
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          key={'qr'}
          columns={columns}
          dataSource={dataSource}
          rowKey={'id'}
          style={{ width: 1000 }}
          scroll={{ x: 1000, y: 300 }}
        />
      </CustomCard>
    </>
  );

  return (
    <CustomPage title={t('requested_qr')} backButton={[<BackButton />]}>
      {TableComponent}
    </CustomPage>
  );
};

export default App;
