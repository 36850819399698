import { useState, useEffect, useCallback } from 'react';
import { useData } from '@/context';

const useGetLicense = () => {
  const [data, setData] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [appInfo, setAppInfo] = useState<any>({});
  const { onFetchData } = useData();

  const doGetLicense = useCallback(async () => {
    setLoading(true);
    try {
      const res = await onFetchData('license/check');

      if (!res.error) {
        const info = {
          customerId: res.data.customerId,
          customerName: res.data.customerName,
          customerType: res.data.customerType,
          lineFeature: res.data.lineFeature,
        };

        setData(res.data.activate);
        setAppInfo(info);

        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      return;
    } finally {
      setLoading(false);
    }
  }, [onFetchData]);

  useEffect(() => {
    doGetLicense();
  }, [doGetLicense]);

  return {
    data,
    loading,
    appInfo,
    refetch: doGetLicense,
  };
};

export default useGetLicense;
